import { Grid, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import moment from "moment";
import cfg from "../../../cfg.json";

function HistoryCards(props) {
  let history = props.history;
  return (
    <Grid container item alignItems='center' justifyContent='center' key='historyCard'>
      {history.map(h => {
        let typeClassName = "text-success";
        if (h.type === "update") {
          typeClassName = "text-warning";
        }
        return (
          <Grid key={h.dateStamp} item className='p-1'>
            <Card>
              <CardContent className='bg-light'>
                <Typography>{"User: " + h.user}</Typography>
                <Typography>{"Date: " + moment(h.dateStamp).format(cfg.dateFormat)}</Typography>
                <Typography className={typeClassName}>{"Type: " + h.type}</Typography>
                <Grid style={{ height: 2 }} className='bg-Smain w-50 mx-auto my-2' />
                {h.data ? <Typography align='center'>{"Changes"}</Typography> : null}
                {h.data
                  ? Object.keys(h.data).map((d, i) => {
                      let hh = h.data[d];
                      let oldValue = hh.oldValue ? hh.oldValue : "none";
                      let newValue = hh.newValue ? hh.newValue : "Empty";
                      if (hh.type === "date") {
                        // need to format as data;
                        oldValue = oldValue ? moment(oldValue).format(cfg.dateFormat) : null;
                        newValue = moment(newValue).format(cfg.dateFormat);
                      }
                      return (
                        <Grid key={"changes_" + i}>
                          <Typography>
                            <span className='font-italic'>{hh.alias + ": "}</span>
                            <strong>{oldValue}</strong>
                            {" > "}
                            <strong>{newValue}</strong>
                          </Typography>
                        </Grid>
                      );
                    })
                  : null}
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default HistoryCards;
