import { reqCreateData, reqGetData, reqUpdateData } from "./req";
import RoomIcon from "@material-ui/icons/Room";
import WorkIcon from "@material-ui/icons/Work";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import GroupIcon from "@material-ui/icons/Group";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import StoreIcon from "@material-ui/icons/Store";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { isUserAuthenticated } from "./auth";

export function dataFilter(opt, cb) {
  let value = replaceDiacritice(opt.filter).toLowerCase();
  let data = opt.data;
  let filtered = [];
  if (Array.isArray(data)) {
    // this is array, need to pass
    data.forEach((d, i) => {
      let valid = false;
      let keys = Object.keys(d);
      keys.forEach((k, j) => {
        let v = d[k];
        if (typeof v === "string") {
          let kValue = replaceDiacritice(v).toLowerCase();
          if (kValue.includes(value)) {
            // this is valid
            valid = true;
          }
        }

        if (typeof v === "object") {
          /// this is object,
          Object.keys(v).forEach(k => {
            let vv = v[k];
            if (vv && typeof vv === "string") {
              let kValue = replaceDiacritice(vv).toLowerCase();
              if (kValue.includes(value)) {
                // this is valid
                valid = true;
              }
            }
          });
        }

        if (j === keys.length - 1) {
          // las valid
          if (valid) {
            filtered.push(d);
          }
        }

        if (j === keys.length - 1 && i === data.length - 1) {
          /// this is the last general item
          cb(filtered);
        }
      });
    });
  } else {
    cb(filtered);
  }
}

export function getTabs(opt) {
  let target = opt.target;
  switch (target) {
    case "documents":
      return [
        {
          value: "overview",
          alias: "Documente",
          reference: "documents",
        },
      ];
    case "participants":
      return [
        {
          value: "overview",
          alias: "Overview",
        },
        {
          value: "details",
          alias: "Contact details",
        },
        {
          value: "documents",
          alias: "Documents",
        },
        {
          value: "notes",
          alias: "Notes",
        },
        {
          value: "history",
          alias: "History",
          type: "history",
        },
      ];
    case "accommodation":
      return [
        {
          value: "overview",
          alias: "Overview",
        },
        {
          value: "participants",
          alias: "Accommodation participants",
          type: "table",
          reference: "participants",
        },
        {
          value: "description",
          alias: "Description",
        },
        {
          value: "history",
          alias: "History",
          type: "history",
        },
      ];

    case "jobs":
      return [
        {
          value: "overview",
          alias: "Job details",
        },
        {
          value: "participants",
          alias: "Job participants",
          type: "table",
          reference: "participants",
        },
        {
          value: "history",
          alias: "History",
          type: "history",
        },
      ];

    case "farms":
      return [
        {
          value: "overview",
          alias: "Farm overview",
        },
        {
          value: "jobs",
          alias: "Farm Jobs",
          type: "table",
          reference: "jobs",
        },
        {
          value: "photogallery",
          alias: "Photo gallery",
        },
        {
          value: "history",
          alias: "History",
          type: "history",
        },
      ];
    case "users":
      return [
        {
          value: "overview",
          alias: "User Overview",
        },
        {
          value: "history",
          alias: "History",
          type: "history",
        },
      ];
    case "profiles":
      return [
        {
          value: "overview",
          alias: "Profile Overview",
        },
        {
          value: "users",
          alias: "Profile Users",
          type: "table",
          reference: "users",
        },
        {
          value: "history",
          alias: "History",
          type: "history",
        },
      ];

    default:
      break;
  }
}

export function getItem(opt) {
  let id = opt.id;
  let data = opt.data;
  let item = data.filter(d => d._id === id);
  return item[0];
}

export function saveData(opt, cb) {
  let target = opt.target;
  let data = opt.data;
  reqCreateData({ target, data }, c => {
    if (c) {
      // update farms
      reqGetData({ target }, c => {
        cb(c);
      });
    } else {
      cb(false);
      // not update // alert farm
    }
  });
}

export function updateData(opt, cb) {
  reqUpdateData(opt, c => {
    if (c) {
      // update farms
      reqGetData({ target: opt.target }, c => {
        cb(c);
      });
    } else {
      cb(false);
      // not update // alert farm
    }
  });
}

export function getTabMenu() {
  if (isUserAuthenticated()) {
    // show meniu for superadmin user;

    if (localStorage.getItem("type") === "superadmin") {
      return [
        {
          value: "Users",
          target: "/users",
          icon: <GroupIcon />,
        },
        {
          value: "Profiles ",
          target: "/profiles",
          icon: <SupervisorAccountIcon />,
        },
        {
          value: "Agencies",
          target: "/agencies",
          icon: <StoreIcon />,
        },
        {
          value: "Logout",
          target: "/logout",
          icon: <ExitToAppIcon />,
        },
      ];
    } else {
      return [
        {
          value: "Dashboard",
          target: "/dashboard",
          icon: <DashboardIcon />,
        },
        {
          value: "Participants",
          target: "/participants",
          icon: <GroupIcon />,
        },
        {
          value: "Jobs",
          target: "/jobs",
          icon: <WorkIcon />,
        },
        {
          value: "Farms",
          target: "/farms",
          icon: <LocationCityIcon />,
        },
        {
          value: "Accommodations",
          target: "/accommodation",
          icon: <RoomIcon />,
        },
        {
          value: "Documents",
          target: "/showdocs",
          icon: <AssignmentIcon />,
        },
        {
          value: "Logout",
          target: "/logout",
          icon: <ExitToAppIcon />,
        },
      ];
    }
  } else {
    return [
      {
        value: "Home",
        target: "/",
        icon: <HomeIcon />,
      },
      {
        value: "Contact",
        target: "/contact",
        icon: <EmailIcon />,
      },
      {
        value: "Documente",
        target: "/documente",
        icon: <EmailIcon />,
      },
      {
        value: "Login",
        target: "/login",
        icon: <LockOpenIcon />,
      },
    ];
  }
}

export function replaceDiacritice(string) {
  string = string
    .replace(/ă|â/gi, "a")
    .replace(/ț/gi, "t")
    .replace(/î/gi, "i")
    .replace(/ș/gi, "s");

  return string;
}
