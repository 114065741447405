import React from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import "./App.css";
import "./custom.scss";
import config from "./cfg.json";
import { Provider } from "react-redux";
import store from "./redux/store";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Main from "./Components/Main";
import Participants from "./Components/Pages/Participants";
import Jobs from "./Components/Pages/Jobs";
import Farms from "./Components/Pages/Farms";
import Contact from "./Components/Pages/Contact";
import Login from "./Components/Pages/Login";
import Logout from "./Components/Pages/Logout";
import Dashboard from "./Components/Pages/Dashboard";
import Alerts from "./Components/Pages/Alerts";
import Item from "./Components/Pages/Item";
import Accommodation from "./Components/Pages/Accommodation";
import Profiles from "./Components/Pages/Profiles";
import Users from "./Components/Pages/Users";
import Agencies from "./Components/Pages/Agencies";
import { Documents, DocumentsShow } from "./Components/Pages/Documents";

const theme = createTheme({
  palette: {
    primary: config.primary,
    secondary: config.secondary,
  },
  typography: {
    useNextVariants: true,
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact={true} path="/" component={Main} />
            <Route exact={true} path="/item" component={Item} />
            <Route exact={true} path="/participants" component={Participants} />
            <Route
              exact={true}
              path="/accommodation"
              component={Accommodation}
            />
            <Route exact={true} path="/jobs" component={Jobs} />
            <Route exact={true} path="/farms" component={Farms} />
            <Route exact={true} path="/contact" component={Contact} />
            <Route exact={true} path="/documente" component={Documents} />
            <Route exact={true} path="/login" component={Login} />
            <Route exact={true} path="/logout" component={Logout} />
            <Route exact={true} path="/dashboard" component={Dashboard} />
            <Route exact={true} path="/users" component={Users} />
            <Route exact={true} path="/profiles" component={Profiles} />
            <Route exact={true} path="/agencies" component={Agencies} />
            <Route exact={true} path="/showdocs" component={DocumentsShow} />

            <Redirect to="/" />
          </Switch>
        </Router>
        <Alerts />
      </Provider>
    </MuiThemeProvider>
  );
}

export default App;
