import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import Header from "../Menu/Header";

class Dashboard extends Component {
  render() {
    return (
      <Grid container>
        <Header tab={0} />
        <Grid item xs={12}>
          <Typography variant='h5' align='center' className='my-3 text-Pmain'>
            {"Empty for the moment "}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default Dashboard;
