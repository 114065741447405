import {
  Grid,
  Paper,
  Tabs,
  Typography,
  Tab,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { Redirect } from "react-router";
import store from "../../redux/store";
import Actions from "../Menu/Actions";
import Header from "../Menu/Header";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import PrintIcon from "@material-ui/icons/Print";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import FieldsShow from "../data/dataShow/fields";
import WorkIcon from "@material-ui/icons/Work";
import RoomIcon from "@material-ui/icons/Room";
import GetAppIcon from "@material-ui/icons/GetApp";
import { getItem, saveData, updateData, getTabs } from "../../fn/data";
import SaveIcon from "@material-ui/icons/Save";
import { setAlert, setItem } from "../../redux/actions/menu";
import {
  getFields,
  getUsers,
  reqGetData,
  reqGetDocs,
  reqRemoveDocs,
  reqSaveDocs,
} from "../../fn/req";
import async from "async";
import HistoryCards from "../data/dataShow/historyCards";
import PdfShow from "../data/dataShow/pdfShow";

const { default: Inputs } = require("../data/dataInput/inputs");
const { default: TableShow } = require("../data/dataShow/table");
const { Component } = require("react");

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        open: false,
      },
      docs: {},
      tab: 0,
      form: {},
      allowEdit: {},
      allowDelete: true,
      fields: {},
      redirectTo: "",
      redirect: false,
      allowAdd: false,
    };
  }

  componentDidMount() {
    let state = store.getState();
    let item = state.menu.item;
    if (!item) {
      // load with no item
      this.setState({ redirect: true, redirectTo: "/" });
    } else {
      let tab = item.tabs[0];
      // get docs for this item

      this.setAllFields({ tabs: item.tabs, origin: item.origin });

      getFields(
        {
          target: item.reference ? item.reference : item.origin,
          tab: tab.value,
        },
        cb => {
          this.getDocs(
            {
              id: item.data._id,
              fields: cb.fields,
            },
            docs => this.setState({ docs })
          );
        }
      );
    }
  }

  setAllFields = opt => {
    const tabs = opt.tabs;
    const origin = opt.origin;
    tabs.forEach(t => {
      // get fields for this
      let target = t.reference ? t.reference : origin;
      let tab = t.reference ? "overview" : t.value;
      getFields({ target, tab }, cb => {
        let allFields = { ...this.state.fields };
        allFields[t.value] = cb.fields;
        let tempState = { fields: allFields };

        if (this.state.allowDelete && !cb.allowDelete) {
          tempState.allowDelete = cb.allowDelete;
        }

        if (this.state.allowAdd && !cb.allowAdd) {
          tempState.allowAdd = cb.allowAdd;
        }

        if (cb.allowAdd && !this.state.allowAdd) {
          tempState.allowAdd = true;
        }

        this.setState(tempState);
      });
    });
  };

  getDocs = (opt, cb) => {
    // get docs for current tab
    let fields = opt.fields;
    var docs = {};
    if (fields && fields.length) {
      // we have fields
      let total = 0;
      fields.forEach((f, i) => {
        if (f.type === "file") {
          // get Docs for this field
          reqGetDocs({ id: opt.id, target: f.value }, c => {
            if (c) {
              docs[f.value] = c;
            }
            total++;

            if (total === fields.length) {
              cb(docs);
            }
          });
        } else {
          total++;
          if (total === fields.length) {
            cb(docs);
          }
        }
      });
    } else {
      cb(docs);
    }
  };

  deleteDocs = opt => {
    let state = store.getState();
    let item = state.menu.item;
    opt.targetTab = item.origin;
    opt.id = item.data._id;
    this.setState({
      modal: {
        open: true,
        text: "Are you sure?",
        actions: [
          this.closeButton({ modal: { open: false } }),
          {
            value: "delete",
            alias: "YES",
            action: () =>
              reqRemoveDocs(opt, cb => {
                // close modal
                this.setState({ modal: { open: false } });

                if (cb) {
                  // we have removed item
                  store.dispatch(
                    setAlert({
                      text: "Document removed",
                      open: true,
                      timeout: 2000,
                      severity: "success",
                    })
                  );

                  // need to update items
                  // get docs if necesary

                  getFields(
                    {
                      target: item.reference ? item.reference : item.origin,
                      tab: item.tabs[this.state.tab].value,
                    },
                    cb => {
                      this.getDocs(
                        {
                          id: opt.id,
                          fields: cb.fields,
                        },
                        docs => this.setState({ docs })
                      );
                    }
                  );
                } else {
                  store.dispatch(
                    setAlert({
                      text: "Not able to remove document",
                      open: true,
                      timeout: 2000,
                      severity: "error",
                    })
                  );
                }
              }),
            color: "secondary",
            startIcon: <DeleteIcon />,
          },
        ],
      },
    });
  };

  closeButton = opt => {
    let state = opt;
    return {
      value: "close",
      alias: "Close",
      action: () => this.setState(state),
      color: "secondary",
      startIcon: <CloseIcon />,
    };
  };

  deleteItem = opt => {
    updateData(
      {
        target: opt.target,
        id: opt.id,
        data: { itemState: false },
      },
      cb => {
        if (cb) {
          store.dispatch(setItem(null));
          this.setState({ redirect: true, redirectTo: "/" + opt.target });
          store.dispatch(
            setAlert({
              text: "Item deleted",
              open: true,
              timeout: 2000,
              severity: "success",
            })
          );
        } else {
          // not able to delete
          store.dispatch(
            setAlert({
              text: "Not able to delete item",
              open: true,
              timeout: 2000,
              severity: "error",
            })
          );
        }
      }
    );
  };

  generatePdf = opt => {
    let tabs = opt.item.tabs;
    let target = opt.item.referance ? opt.item.referance : opt.item.origin;

    // generate inputs
    let inputs = [
      {
        value: "printTitle",
        alias: "Title",
        fullWidth: true,
        xs: 5,
      },
    ];

    inputs.push({
      value: "imageD",
      alias: "Picture width",
      variants: [
        { value: "small", alias: "Small" },
        { value: "medium", alias: "Medium" },
        { value: "large", alias: "Large" },
      ],
      type: "select",
      xs: 5,
    });

    let fieldsVariants = [];

    // get all fields

    let requests = [];
    tabs.forEach(t => {
      if (t.type !== "history" && t.type !== "table") {
        requests.push(c =>
          getFields({ target, tab: t.value }, cb => {
            c(null, cb);
          })
        );
      }
    });

    async.parallel(requests, (e, r) => {
      r.forEach(rr => (fieldsVariants = fieldsVariants.concat(rr.fields)));
      inputs.push({
        value: "fields",
        alias: "Fields to print",
        variants: fieldsVariants,
        type: "autocomplete",
        multiple: true,
        xs: 12,
        md: 12,
        fullWidth: true,
      });

      // add extraInfo values
      inputs.push({
        value: "extraInfo",
        alias: "Extra Info",
        rows: 3,
        fullWidth: true,
        xs: 12,
        md: 12,
      });

      // add dimensions

      var handleGenerateAction = () => {
        let form = { ...this.state.form };
        if (form && form.fields) {
          /// generate

          // check if we need docs
          let docsFields = [];

          form.fields.forEach(f => {
            if (f.type === "file") {
              // this is file field
              docsFields.push(f);
            }
          });

          if (docsFields.length) {
            // we have some file filed that we need to get
            this.getDocs(
              {
                id: opt.item.data._id,
                fields: docsFields,
              },
              docs => {
                this.setState({
                  pdfOpen: true,
                  pdfDocs: docs,
                  pdfInputs: fieldsVariants,
                });
              }
            );
          } else {
            this.setState({
              pdfOpen: true,
              pdfInputs: fieldsVariants,
            });
          }
        } else {
          // we don't have any form
          store.dispatch(
            setAlert({
              text: "No data entered",
              open: true,
              severity: "info",
            })
          );
        }
      };

      let actions = [
        this.closeButton({ modal: { open: false }, form: {} }),
        {
          value: "Print",
          alias: "Generate",
          className: "bg-success text-white",
          action: handleGenerateAction,
        },
      ];

      this.setState({
        form: { imageD: "small" },
        modal: {
          open: true,
          title: "Generate Pdf",
          actions,
          inputs,
        },
      });
    });
  };

  actions = item => {
    let type = item.type;
    let tab = item.tabs[this.state.tab];
    let tabValue = tab.value;
    let allowDelete = this.state.allowDelete;
    let allowEdit = false;
    if (this.state.fields[tabValue]) {
      this.state.fields[tabValue].forEach(f => {
        if (!f.disabled && !f.target) {
          // allow to edit
          allowEdit = true;
        }
      });
    }

    let actions = [];
    switch (type) {
      case "view":
        if (allowDelete) {
          actions.push({
            value: "delete",
            alias: "Delete",
            className: "bg-danger text-white",
            action: () =>
              this.setState({
                modal: {
                  open: true,
                  title: "Remove item",
                  text: "Are you sure?",
                  actions: [
                    {
                      value: "close",
                      alias: "Close",
                      action: () => this.setState({ modal: { open: false } }),
                      color: "secondary",
                      startIcon: <CloseIcon />,
                    },
                    {
                      value: "delete",
                      alias: "YES",
                      action: () =>
                        this.deleteItem({
                          id: item.data._id,
                          target: item.reference ? item.reference : item.origin,
                        }),
                      color: "secondary",
                      startIcon: <DeleteIcon />,
                    },
                  ],
                },
              }),
            startIcon: <DeleteIcon />,
          });
        }

        // push generatePdf button
        if (!item.disablePdf) {
          actions.push({
            value: "generatePDF",
            alias: "PDF",
            className: "bg-success text-white",
            action: () =>
              this.generatePdf({
                item,
              }),
            startIcon: <PrintIcon />,
          });
        }

        if (tabValue !== "history" && tab.type !== "table" && allowEdit) {
          // if tab is not history tab then push edit
          actions.push({
            value: "editItem",
            alias: "Edit",
            className: "bg-Pmain text-white",
            action: () => this.editItem(item),
            startIcon: <EditIcon />,
          });
        }
        break;
      case "addJobs":
        break;

      default:
        break;
    }

    actions.push({
      value: "close",
      alias: "Close",
      className: "bg-info text-white",
      action: () => {
        // remove item from store
        store.dispatch(setItem(null));
        this.setState({ redirect: true, redirectTo: "/" + item.origin });
      },
      startIcon: <CloseIcon />,
    });

    return actions;
  };

  handleTabChange(v) {
    store.dispatch(
      setAlert({
        text: "Loading tab",
        open: true,
        timeout: 5000,
        severity: "info",
      })
    );
    this.setState({ tab: v, docs: {} }, () => {
      // get docs for now active tab
      let state = store.getState();
      let item = state.menu.item;
      let tab = item.tabs[v];

      getFields(
        {
          target: item.reference ? item.reference : item.origin,
          tab: tab.value,
        },
        cb => {
          this.getDocs(
            {
              id: item.data._id,
              fields: cb.fields,
            },
            docs =>
              this.setState({ docs }, () => {
                store.dispatch(
                  setAlert({
                    text: "Tab loaded ",
                    open: true,
                    timeout: 1000,
                    severity: "success",
                  })
                );
              })
          );
        }
      );
    });
  }

  handleChange(opt) {
    let form = { ...this.state.form };
    let type = opt.type;
    let value = opt.value;
    let saveState = true;
    if (type && type === "file") {
      /// save as file
      if (opt.limit && value.length > opt.limit) {
        // check limit
        saveState = false;
        store.dispatch(
          setAlert({
            open: true,
            text: "Maximul " + opt.limit + " items allowed ",
            severity: "error",
          })
        );
      }
      value = { type: "file", data: value };
    }
    form[opt.target] = value;
    if (saveState) {
      this.setState({ form });
    }
  }

  generateTabs(tabs) {
    return (
      <Paper className="my-1">
        <Tabs
          value={this.state.tab}
          centered
          textColor="primary"
          indicatorColor="secondary"
          onChange={(e, v) => this.handleTabChange(v)}
        >
          {tabs.map(t => {
            return <Tab key={t.value} label={t.alias ? t.alias : t.value} />;
          })}
        </Tabs>
      </Paper>
    );
  }

  saveData(opt) {
    let target = opt.target;
    saveData({ target, data: this.state.form }, cb => {
      if (cb) {
        this.setState(
          {
            modal: { open: false },
            form: {},
            jobs: null,
          },
          () => {
            store.dispatch(
              setAlert({
                text: "Item saved",
                open: true,
                timeout: 2000,
                severity: "success",
              })
            );
          }
        );
      } else {
        store.dispatch(
          setAlert({
            text: "Not able to save ",
            open: true,
            timeout: 2000,
            severity: "error",
          })
        );
      }
    });
  }

  updateData(opt) {
    let docData = {};
    let data = opt.data;
    let updated = false;

    let dataKeys = Object.keys(data);
    let dataLength = dataKeys.length;
    const item = opt.item;

    const updateCB = cb => {
      // we have update the item

      let currentTab = item.tabs[this.state.tab];

      // get docs if necesary
      getFields(
        {
          target: item.reference ? item.reference : item.origin,
          tab: currentTab.value,
        },
        cb => {
          this.getDocs(
            {
              id: item.data._id,
              fields: cb.fields,
            },
            docs => {
              this.setState({ docs, form: {}, modal: { open: false } }, () => {
                store.dispatch(
                  setAlert({
                    text: "Item updated",
                    open: true,
                    timeout: 2000,
                    severity: "success",
                  })
                );
              });
            }
          );
        }
      );
    };

    dataKeys.forEach((d, j) => {
      item.tabs.forEach((t, i) => {
        const tab = t.value;
        getFields({ target: opt.target, tab }, cb => {
          let field = cb.fields.filter(f => f.value === d)[0];
          if (field && field.disabled) {
            // erase this value from data
            delete data[d];
          }

          if (data[d] && data[d].type && data[d].type === "file") {
            // need to handle file for this one
            docData[d] = { ...data[d] };
            delete data[d];
          }

          if (i === opt.item.tabs.length - 1 && j === dataLength - 1) {
            // go on

            if (Object.keys(docData).length) {
              // we have docData to save
              Object.keys(docData).forEach(d => {
                let fData = new FormData();
                fData.append("id", opt.id);
                fData.append("target", d);
                Object.keys(docData[d].data).forEach((f, i) => {
                  fData.append("file", docData[d].data[f]);
                });

                store.dispatch(
                  setAlert({
                    text: "Uploading Files",
                    open: true,
                    timeout: 5000,
                    severity: "success",
                  })
                );

                reqSaveDocs(fData, cb => {
                  // this is uploaded
                  if (cb && !updated) {
                    updated = true;
                    updateCB(cb);
                  } else {
                    store.dispatch(
                      setAlert({
                        text: "Not able to Update ",
                        open: true,
                        timeout: 2000,
                        severity: "error",
                      })
                    );
                  }
                });
              });
            }

            if (dataLength) {
              updateData(
                {
                  target: opt.target,
                  id: opt.id,
                  data,
                },
                cb => {
                  if (cb && !updated) {
                    /// call to save
                    let item = opt.item;
                    updated = true;
                    item.data = cb.filter(c => c._id === opt.id)[0];
                    // save updated item
                    store.dispatch(setItem(item));
                    updateCB(cb);
                  } else {
                    store.dispatch(
                      setAlert({
                        text: "Not able to Update ",
                        open: true,
                        timeout: 2000,
                        severity: "error",
                      })
                    );
                  }
                }
              );
            }
          }
        });
      });
    });
  }

  editItem = opt => {
    let localTab = this.state.tab;
    let editTarget = opt.tabs[localTab];
    let disabledEdit = ["jobId", "targetAgencie", "accommodationId"];

    getFields(
      {
        target: opt.item.reference ? opt.item.reference : opt.item.origin,
        tab: editTarget.value,
      },
      cb => {
        let inputs = cb.fields.map(i => {
          if (disabledEdit.includes(i.value)) {
            i.disabled = true;
          }
          return i;
        });
        let form = {};
        if (inputs) {
          inputs.forEach(i => {
            let val = opt.data[i.value];
            if (val && i.type !== "file") {
              form[i.value] = val;
            }
          });
        }

        this.setState({
          form,
          modal: {
            open: true,
            title: "Edit item",
            inputs,
            actions: [
              {
                value: "close",
                alias: "Close",
                action: () => this.setState({ modal: { open: false } }),
                color: "secondary",
                startIcon: <CloseIcon />,
              },
              {
                value: "update",
                alias: "Update",
                color: "primary",
                action: () =>
                  this.updateData({
                    target: opt.origin,
                    id: opt.data._id,
                    data: { ...this.state.form },
                    item: opt,
                  }),
                startIcon: <SaveIcon />,
              },
            ],
          },
        });
      }
    );
  };

  addJobs = opt => {
    getFields({ target: "jobs", tab: "overview" }, cb => {
      let inputs = cb.fields.map(i => {
        if (i.value === "farmName") {
          i.disabled = true;
        }
        return i;
      });
      this.setState({
        form: { farmId: opt.target, farmName: opt.farmName, city: opt.city },
        modal: {
          open: true,
          title: "Add job",
          inputs: inputs,
          actions: [
            {
              value: "close",
              alias: "Close",
              action: () => this.setState({ modal: { open: false } }),
              color: "secondary",
              startIcon: <CloseIcon />,
            },
            {
              value: "save",
              alias: "Save",
              color: "primary",
              action: () => this.saveData({ target: "jobs" }),
              startIcon: <SaveIcon />,
            },
          ],
        },
      });
    });
  };

  alocateParticipant = opt => {
    //get all Jobs and save person for job available
    switch (opt.type) {
      case "toJob":
        reqGetData(
          {
            target: "jobs",
            specials: {
              availablePlaces: true,
              fields: ["id", "jobName", "farmName"],
            },
          },
          cb => {
            let variants = [
              {
                value: null,
                alias: "No job",
              },
            ];

            cb.forEach(v => {
              variants.push({
                value: v._id,
                alias:
                  "ID: " +
                  v.id +
                  ", JOB: " +
                  v.jobName +
                  ", FARM: " +
                  v.farmName,
              });
            });

            this.setState({
              form: {},
              modal: {
                open: true,
                title: "Alocate to job",
                inputs: [
                  {
                    value: "jobId",
                    alias: "Job",
                    xs: 12,
                    fullWidth: true,
                    type: "autocomplete",
                    variants,
                  },
                ],
                actions: [
                  {
                    value: "close",
                    alias: "Close",
                    action: () => this.setState({ modal: { open: false } }),
                    color: "secondary",
                    startIcon: <CloseIcon />,
                  },
                  {
                    value: "save",
                    alias: "Save",
                    color: "primary",
                    action: () =>
                      this.updateData({
                        target: "participants",
                        data: {
                          jobId: this.state.form.jobId
                            ? this.state.form.jobId.value
                            : null,
                        },
                        id: opt.target,
                        item: opt.item,
                      }),
                    startIcon: <SaveIcon />,
                  },
                ],
              },
            });
          }
        );
        break;
      case "toAccommodation":
        reqGetData(
          {
            target: "accommodation",
            specials: {
              availablePlaces: true,
            },
          },
          cb => {
            let variants = [
              {
                value: null,
                alias: "No Accommodafion",
              },
            ];

            cb.forEach(v => {
              variants.push({
                value: v._id,
                alias:
                  "ID: " +
                  v.id +
                  ", Name: " +
                  v.accommodationName +
                  ", Available places: " +
                  v.availablePlaces,
              });
            });

            this.setState({
              modal: {
                open: true,
                title: "Allocate accommodation",
                inputs: [
                  {
                    value: "accommodationId",
                    alias: "Accomodation",
                    variants,
                    xs: 12,
                    fullWidth: true,
                    type: "autocomplete",
                  },
                ],
                actions: [
                  {
                    value: "close",
                    alias: "Close",
                    action: () => this.setState({ modal: { open: false } }),
                    color: "secondary",
                    startIcon: <CloseIcon />,
                  },
                  {
                    value: "save",
                    alias: "Save",
                    color: "primary",
                    action: () =>
                      this.updateData({
                        target: "participants",
                        data: {
                          accommodationId: this.state.form.accommodationId
                            ? this.state.form.accommodationId.value
                            : null,
                        },
                        id: opt.target,
                        item: opt.item,
                      }),
                    startIcon: <SaveIcon />,
                  },
                ],
              },
            });
          }
        );
        break;
      case "toAgencie":
        getUsers(
          {
            target: "agencies",
            filter: {
              alocatedItems: true,
            },
          },
          cb => {
            let variants = cb
              ? cb.map(v => {
                  return { value: v._id, alias: v.name };
                })
              : [];

            let itemData = opt.item.data;
            let form = { ...this.state.form };
            form.targetAgencie = itemData.targetAgencie
              ? itemData.targetAgencie.map(a => {
                  return a._id;
                })
              : null;

            this.setState({
              form,
              modal: {
                open: true,
                title: "Allocate agencies",
                inputs: [
                  {
                    value: "targetAgencie",
                    alias: "Agencies",
                    variants,
                    type: "multiple",
                  },
                ],
                actions: [
                  {
                    value: "close",
                    alias: "Close",
                    action: () => this.setState({ modal: { open: false } }),
                    color: "secondary",
                    startIcon: <CloseIcon />,
                  },
                  {
                    value: "save",
                    alias: "Save",
                    color: "primary",
                    action: () =>
                      this.updateData({
                        target: opt.item.origin,
                        data: {
                          targetAgencie: this.state.form.targetAgencie,
                        },
                        id: opt.target,
                        item: opt.item,
                      }),
                    startIcon: <SaveIcon />,
                  },
                ],
              },
            });
          }
        );
        break;
      default:
        break;
    }
  };

  extraActions = opt => {
    let target = opt.target;
    let item = opt.item;
    let tabs = item.tabs;
    let actions = [];
    var checkValid = field => {
      var allowed = false;
      tabs.forEach(t => {
        if (this.state.fields[t.value]) {
          this.state.fields[t.value].forEach(f => {
            if (f.value === field && !f.disabled) {
              // allow to edit / add this field
              allowed = true;
            }
          });
        }
      });
      return allowed;
    };

    switch (target) {
      case "farms":
        if (this.state.allowAdd) {
          actions.push({
            value: "addJob",
            alias: "Add job",
            className: "bg-info text-white",
            action: () =>
              this.addJobs({
                target: item.data._id,
                farmName: item.data.farmName,
                city: item.data.city,
              }),
            startIcon: <CreateNewFolderIcon />,
          });
        }

        break;
      case "participants":
        if (checkValid("jobId")) {
          actions.push({
            value: "alocateParticipant",
            alias: "Job",
            className: "bg-info text-white",
            action: () =>
              this.alocateParticipant({
                type: "toJob",
                target: item.data._id,
                item,
              }),
            startIcon: <WorkIcon />,
          });
        }

        if (checkValid("accommodationId")) {
          actions.push({
            value: "alocateToAccommodation",
            alias: "Accommodation",
            className: "bg-info text-white",
            action: () =>
              this.alocateParticipant({
                type: "toAccommodation",
                target: item.data._id,
                item,
              }),
            startIcon: <RoomIcon />,
          });
        }

        break;
      default:
        break;
    }

    if (checkValid("targetAgencie")) {
      // push alocate Agencie
      actions.push({
        value: "alocateAgencie",
        alias: "Agencie",
        className: "bg-info text-white",
        action: () =>
          this.alocateParticipant({
            type: "toAgencie",
            target: item.data._id,
            item,
          }),
        startIcon: <RoomIcon />,
      });
    }
    if (actions.length) {
      return (
        <Grid item xs={12} md={8} className="mt-1">
          <Grid style={{ height: 2 }} className="bg-Smain w-50 mx-auto my-2" />
          <Typography align="center" variant="h4">
            {"Alocate"}
          </Typography>
          <Actions actions={actions} />
        </Grid>
      );
    } else {
      return null;
    }
  };

  handleAction(opt) {
    let type = opt.type;
    let reference = opt.reference;
    switch (type) {
      case "view":
        // as view a item outside normal item
        let dataItem = getItem({ data: this.state[reference], id: opt.id });
        let tabs = getTabs({ target: reference });
        store.dispatch(
          setItem({
            tab: 0,
            type: "view",
            titleFields: ["jobName"],
            data: dataItem,
            origin: reference,
            tabs,
          })
        );

        // change to tab 0
        this.setState({
          tab: 0,
        });

        this.setAllFields({
          tabs,
          origin: reference,
        });

        // get docs for this item
        getFields({ target: reference, tab: tabs[0].value }, cb => {
          this.getDocs(
            {
              id: opt.id,
              fields: cb.fields,
            },
            docs => this.setState({ docs })
          );
        });

        break;
      default:
        break;
    }
  }

  selectItem = opt => {
    let item = opt.item;
    let field = opt.field;
    reqGetData({ target: field.target, filter: { _id: item._id } }, cb => {
      if (cb) {
        let tabs = getTabs({ target: field.target });
        Promise.resolve(
          store.dispatch(
            setItem({
              tab: 0,
              type: "view",
              titleFields: [field.alternativeName],
              data: cb[0],
              origin: field.target,
              tabs,
            })
          )
        ).then(() => {
          // update fields
          this.setAllFields({ tabs, origin: field.target });

          // get docs for this one

          getFields({ target: field.target, tab: tabs[0].value }, cb => {
            this.getDocs(
              {
                id: item._id,
                fields: cb.fields,
              },
              docs => this.setState({ docs })
            );
          });
        });
      } else {
        store.dispatch(
          setAlert({
            open: true,
            text: "No item to view",
            severity: "error",
          })
        );
      }
    });
  };

  generateContent = opt => {
    let type = opt.tab.type;
    let scope = opt.origin;
    switch (type) {
      case "table":
        let reference = opt.tab.reference;
        if (reference) {
          // check if we have data
          let data = this.state[reference];
          if (!data) {
            // get data
            let filter = {};
            switch (reference) {
              case "jobs":
                filter.farmId = opt.itemData._id;
                break;
              case "participants":
                switch (scope) {
                  case "jobs":
                    filter.jobId = opt.itemData._id;
                    break;
                  case "accommodation":
                    filter.accommodationId = opt.itemData._id;
                    break;
                  default:
                    break;
                }
                break;
              default:
                break;
            }
            reqGetData({ target: reference, filter }, cb => {
              this.setState({ [reference]: cb });
            });
            return null;
          } else {
            let fields = [...this.state.fields[opt.tab.value]];
            fields.push({
              value: "actions",
              alias: "Actions",
              type: "actions",
              variants: [
                {
                  value: "view",
                  alias: "View",
                  icon: "visibility",
                  action: opt =>
                    this.handleAction({ id: opt, type: "view", reference }),
                },
              ],
            });
            return (
              <TableShow
                tableProps={{ size: "small" }}
                heads={fields}
                lines={data ? data : []}
              />
            );
          }
        } else {
          return null;
        }
      case "history":
        let history = opt.itemData.history;
        if (history && history.length) {
          // construct history
          let allFields = [];

          opt.tabs.forEach(t => {
            allFields.push(...this.state.fields[t.value]);
          });

          let historyData = [];
          history.forEach(h => {
            if (h.data) {
              Object.keys(h.data).forEach(d => {
                let field = allFields.filter(f => f.value === d)[0];
                h.data[d].alias = field.alias;
                h.data[d].type = field.type;
              });
            }
            historyData.push(h);
          });

          return <HistoryCards history={historyData} />;
        } else {
          return (
            <Grid>
              <Typography variant="h4" align="center">
                {"No history to show"}
              </Typography>
            </Grid>
          );
        }
      default:
        return (
          <FieldsShow
            fields={opt.fields}
            values={opt.itemData}
            actions={{
              deleteDocs: this.deleteDocs,
              selectItem: this.selectItem,
            }}
          />
        );
    }
  };

  render() {
    let state = store.getState();
    let item = state.menu.item;
    let itemData = item ? { ...item.data, ...this.state.docs } : null;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectTo} />;
    } else {
      if (!item) {
        return null;
      } else {
        let title = "";
        if (item.data && item.titleFields) {
          item.titleFields.forEach(f => {
            title += item.data[f] ? item.data[f] + " " : "";
          });
        }
        let tab = item.tabs[this.state.tab];
        let fields = this.state.fields[tab.value]
          ? [...this.state.fields[tab.value]]
          : [];

        // set modal
        let modal = this.state.modal;

        return (
          <Grid container>
            <Header tab={item.tab} />
            <Grid item container>
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} className="p-1">
                  <Typography variant="h4" align="center">
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {this.generateTabs(item.tabs)}
                </Grid>

                <Grid item xs={12} md={8} className="mt-2">
                  {this.generateContent({
                    tab,
                    fields,
                    tabs: item.tabs,
                    itemData,
                    origin: item.origin,
                  })}
                </Grid>
                {this.extraActions({ item, target: item.origin })}
                <Grid item xs={12} md={8}>
                  <Grid
                    style={{ height: 2 }}
                    className="bg-Smain w-50 mx-auto my-2"
                  />
                  <Typography align="center" variant="h4">
                    {"Actions"}
                  </Typography>
                  <Actions actions={this.actions(item)} />
                </Grid>
              </Grid>
            </Grid>
            <Dialog
              open={modal.open}
              onClose={() => this.setState({ modal: { open: false } })}
              fullWidth={true}
              maxWidth={"md"}
            >
              <DialogTitle>{modal.title}</DialogTitle>
              <DialogContent>
                {modal.text ? (
                  <Typography variant="h4" align="center">
                    {modal.text}
                  </Typography>
                ) : null}
                <Inputs
                  inputs={modal.inputs}
                  values={this.state.form}
                  handleChange={this.handleChange.bind(this)}
                />
              </DialogContent>
              <Grid className="m-2">
                {this.state.pdfOpen ? (
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <Actions
                        actions={[
                          this.closeButton({
                            modal: { open: false },
                            pdfOpen: false,
                            form: {},
                            pdfInputs: [],
                          }),
                        ]}
                      />
                    </Grid>
                    <Grid>
                      <PdfShow
                        open={this.state.pdfOpen}
                        data={
                          item ? { ...item.data, ...this.state.pdfDocs } : null
                        }
                        form={this.state.form}
                        inputs={this.state.pdfInputs}
                      >
                        <Actions
                          actions={[
                            {
                              value: "download",
                              alias: "Download Pdf",
                              className: "bg-success text-white",
                              startIcon: <GetAppIcon />,
                              action: () => {
                                // close
                                // wait 0.5 seconds
                                setTimeout(() => {
                                  this.setState({
                                    pdfOpen: false,
                                    pdfInputs: null,
                                    form: {},
                                    modal: { open: false },
                                  });
                                }, 500);
                              },
                            },
                          ]}
                        />
                      </PdfShow>
                    </Grid>
                  </Grid>
                ) : (
                  <Actions actions={modal.actions} />
                )}
              </Grid>
            </Dialog>
          </Grid>
        );
      }
    }
  }
}

export default Item;
