import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import Header from "../Menu/Header";
import { Redirect } from "react-router-dom";
import { deauthenticateUser } from "../../fn/auth";

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      redirectTo: "/",
    };
  }

  componentDidMount() {
    // make all the logout logic
    deauthenticateUser();
    this.setState({ redirect: true });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectTo} />;
    } else {
      return (
        <Grid container>
          <Header tab={6} />
          <Grid item xs={12}>
            <Typography variant="h4" align="center" className="my-3 text-Pmain">
              Logout logic
            </Typography>
          </Grid>
        </Grid>
      );
    }
  }
}

export default Logout;
