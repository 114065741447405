import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import cfg from "../../../cfg.json";

function PdfShow(props) {
  let open = props.open;
  let itemData = props.data;
  let form = props.form;
  let borderColor = "#B2BCAA";
  let defaults = {
    marginLeft: "2%",
    width: "98%",
  };
  let styles = StyleSheet.create({
    title: {
      textAlign: "center",
      fontSize: 13,
      fontWeight: "bold",
      padding: 3,
      borderBottom: 1,
      borderColor,
      marginLeft: defaults.marginLeft,
      width: "30%",
    },
    dataView: {
      marginLeft: defaults.marginLeft,
      width: defaults.width,
      marginVertical: 5,
      display: "flex",
    },
    dataText: {
      fontSize: 8,
      display: "flex",
    },
    dataTextValue: {
      display: "flex",
      fontSize: 12,
      fontWeight: "bold",
    },
    extra: {
      textAlign: "left",
      fontSize: 10,
      padding: 3,
    },
  });

  let pictureWidth = () => {
    switch (form.imageD) {
      case "small":
        return "20%";
      case "medium":
        return "35%";
      case "large":
        return "60%";
      default:
        return "60%";
    }
  };

  let title = form.printTitle ? form.printTitle : null;
  let extraInfo = form.extraInfo ? form.extraInfo : null;

  const PdfDoc = () => (
    <Document title={title}>
      <Page
        style={{
          paddingVertical: 20,
        }}
      >
        {title ? (
          <View style={styles.title}>
            <Text>{title}</Text>
          </View>
        ) : null}

        <View style={styles.dataView}>
          {form.fields && form.fields.length
            ? form.fields.map(f => {
                let field = props.inputs.filter(i => i.value === f.value)[0];
                let value = itemData[f.value];

                if (value) {
                  if (field.type === "file") {
                    return (
                      <View key={f.value} style={{ padding: 2 }}>
                        <Text style={styles.dataText}>{f.alias} : </Text>
                        {field.type === "file" ? (
                          value && value.length ? (
                            value.map((img, i) => {
                              return img.type !== "pdf" ? (
                                <View key={"img_" + i}>
                                  <Image
                                    style={{
                                      width: pictureWidth(),
                                      marginLeft: 2,
                                      marginVertical: 1
                                    }}
                                    src={`data:image/${img.type};base64,${img.data}`}
                                  />
                                </View>
                              ) : null;
                            })
                          ) : null
                        ) : (
                          <Text style={styles.dataTextValue}>{value}</Text>
                        )}
                      </View>
                    );
                  } else {
                    // it type is date then create date display data
                    if (f.type === "date") {
                      value = moment(value).format(cfg.dateFormat);
                    }

                    if (typeof value === "object") {
                      if (Array.isArray(value) && field.type !== "file") {
                        // this is array value
                        value = value
                          .map(v => {
                            return v.alias ? v.alias : v.value;
                          })
                          .join(", ");
                      } else {
                        // this is regular object
                        value = value[f.alternativeValue];
                      }
                    }

                    return (
                      <View key={f.value} style={{ padding: 2 }}>
                        <Text style={styles.dataText}>
                          {f.alias}
                          {": "}
                          <Text style={styles.dataTextValue}>{value}</Text>
                        </Text>
                      </View>
                    );
                  }
                } else {
                  return null;
                }
              })
            : null}
        </View>
        {extraInfo ? (
          <View style={{ marginLeft: defaults.marginLeft, width: "98%" }}>
            <Text
              style={{
                textAlign: "center",
                fontSize: 10,
                borderBottom: 1,
                fontWeight: "bold",
                borderColor,
                margin: 2,
                width: "30%",
              }}
            >
              {"Extra Info"}
            </Text>
            <Text style={styles.extra}>{extraInfo}</Text>
          </View>
        ) : null}
      </Page>
    </Document>
  );

  const generateLink = () => {
    return (
      <PDFDownloadLink document={<PdfDoc />} fileName='Info.pdf'>
        {props.children}
      </PDFDownloadLink>
    );
  };

  return open ? generateLink() : null;
}

export default PdfShow;
