import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import Header from "../Menu/Header";
import Actions from "../Menu/Actions";
import store from "../../redux/store";
import { setAlert } from "../../redux/actions/menu";
import Inputs from "../data/dataInput/inputs";
import { reqLogin } from "../../fn/req";
import { authenticateUser, isUserAuthenticated } from "../../fn/auth";
import { Redirect } from "react-router-dom";
import { getTabMenu } from "./../../fn/data";

class Login extends Component {
  state = { form: {} };

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      redirectTarget: "",
      form: {},
      fields: [
        {
          value: "username",
          alias: "Username",
          required: true,
        },
        {
          value: "password",
          alias: "Password",
          type: "password",
        },
      ],
      actions: [
        {
          value: "Login",
          color: "secondary",
          action: this.login.bind(this),
        },
      ],
    };
  }

  componentDidMount() {
    if (isUserAuthenticated()) {
      // redirect to main
      this.setState({
        redirect: true,
        redirectTarget: "/dashboard",
      });
    }
  }

  login() {
    reqLogin(this.state.form, cb => {
      if (cb) {
        // user is ok to login
        authenticateUser(cb);
        let tabs = getTabMenu();
        this.setState(
          { redirect: true, redirectTarget: tabs[0].target },
          () => {
            store.dispatch(
              setAlert({
                text: "Login successful",
                open: true,
                timeout: 2000,
                severity: "success",
              })
            );
          }
        );
      } else {
        // not ok
        store.dispatch(
          setAlert({
            text: "Invalid credentials",
            open: true,
            severity: "error",
          })
        );
      }
    });
  }

  handleChange = opt => {
    let form = { ...this.state.form };
    form[opt.target] = opt.value;
    this.setState({ form });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectTarget} />;
    } else {
      return (
        <Grid container>
          <Header tab={3} />
          <Grid item xs={12}>
            <Typography variant="h4" align="center" className="my-3 text-Pmain">
              Login credentials
            </Typography>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={12}>
                <Inputs
                  inputs={this.state.fields}
                  values={this.state.form}
                  handleChange={this.handleChange.bind(this)}
                />
              </Grid>
              <Grid item xs={11} md={3}>
                <Actions actions={this.state.actions} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  }
}

export default Login;
