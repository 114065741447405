import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import Header from "../Menu/Header";

class Contact extends Component {
  render() {
    return (
      <Grid container>
        <Header tab={1} />
        <Grid item xs={12}>
          <Typography variant="h4" align="center" className="my-3 text-Pmain">
            Contact
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default Contact;
