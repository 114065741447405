import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Inputs from "./data/dataInput/inputs";
import store from "../redux/store";

import SearchIcon from "@material-ui/icons/Search";
import { setFilter } from "../redux/actions/menu";

var lastDate = new Date();
const typeTimeout = 500;

class Search extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchedValue: "",
			inputs: [
				{
					value: "searchedValue",
					alias: "Search",
				},
			],
		};
	}

	handleChange(opt) {
		lastDate = new Date();
		let searchedValue = opt.value;
		this.setState({ searchedValue }, () => {
			setTimeout(() => {
				if (lastDate.getTime() < new Date().getTime() - typeTimeout) {
					let value =
						this.state.searchedValue && this.state.searchedValue !== ""
							? this.state.searchedValue
							: null;
					store.dispatch(setFilter({ searchedValue: value }));
				}
			}, typeTimeout);
		});
	}

	render() {
		return (
			<Grid container alignItems="center" justifyContent="center">
				<Grid item>
					<Inputs
						inputs={this.state.inputs}
						values={{ searchedValue: this.state.searchedValue }}
						handleChange={this.handleChange.bind(this)}
					/>
				</Grid>
				<Grid item>
					<SearchIcon />
				</Grid>
			</Grid>
		);
	}
}

export default Search;
