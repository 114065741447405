import {
	Card,
	CardContent,
	CardMedia,
	Grid,
	Typography,
} from "@material-ui/core";
import Header from "./Menu/Header";

const { Component } = require("react");

class Main extends Component {
	render() {
		return (
			<Grid container>
				<Header tab={0} />
				<Grid
					container
					alignItems="center"
					justifyContent="center"
					className="my-3 text-Pmain"
				>
					<Typography variant="h2" className="m-2" align="center">
						Welcome to work in farms from Nederland!
					</Typography>
				</Grid>
				<Grid
					container
					alignItems="center"
					justifyContent="center"
					className="mb-3"
				>
					<Grid item xs={12}>
						<Card style={{ boxShadow: "none", height: 600 }}>
							<CardMedia className="w-100 h-100" image="../../holland-1.jpg" />
						</Card>
					</Grid>
				</Grid>
				<Grid container alignItems="center" justifyContent="center">
					<Typography variant="h3" className="text-Plight">
						Who we are?
					</Typography>
					<Card style={{ boxShadow: "none" }}>
						<Grid container className="p-3">
							<Grid item xs={12} sm={2} lg={1}>
								<CardMedia
									image="../../pavel.jpg"
									style={{
										height: "100px",
										width: "100px",
										borderRadius: "50%",
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={10} lg={11}>
								<CardContent>
									<Typography gutterBottom variant="h5" className="text-Pmain">
										Pavel Smantana - SC New Oportunities SRL Iasi
									</Typography>
									<Typography>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Maiores consectetur non a, unde excepturi iusto voluptates!
										Aliquid placeat possimus neque cupiditate fuga, unde
										recusandae quasi repellat deserunt enim, perspiciatis saepe
										autem, aperiam soluta doloremque necessitatibus distinctio
										obcaecati! Numquam in excepturi tempore quae corrupti,
										voluptates illo autem laboriosam iure soluta quibusdam
										possimus quisquam porro molestiae nisi sapiente quod amet
										sed blanditiis fugiat ut? Nostrum optio maiores sunt vero
										amet incidunt. Corrupti enim minus dicta dolorum nulla
										consequuntur rerum similique. Excepturi, inventore assumenda
										nostrum et modi unde error natus est, dignissimos
										doloremque, labore repellat distinctio? Accusantium vel
										aspernatur, nam fugit nihil accusamus, deleniti pariatur
										inventore, repudiandae ea quod similique natus impedit
										maiores eos optio. Consequuntur in cupiditate possimus
										earum. Quisquam magni dicta, tempora dolor, necessitatibus
										numquam non, corrupti excepturi exercitationem earum quasi
										doloremque quas? Tempora eveniet, placeat distinctio quo
										culpa, incidunt ducimus aut error mollitia necessitatibus
										quaerat quia voluptate fuga excepturi quisquam quibusdam
										sequi quasi modi molestias voluptatibus consequatur illo
										recusandae in. Consequatur iste error similique mollitia
										quae aperiam deleniti, harum velit ducimus repudiandae
										repellendus, corporis repellat delectus debitis tenetur ea
										obcaecati odit incidunt sed nesciunt quis perspiciatis
										sapiente? Ipsam, id at architecto, ratione ab iste animi
										aperiam sed eum minima nihil.
									</Typography>
								</CardContent>
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>
		);
	}
}

export default Main;
