const axios = require("axios");
const { isUserAuthenticated, deauthenticateUser } = require("./auth");

module.exports.reqLogin = (opt, cb) => {
  axios({
    method: "POST",
    data: opt,
    url: "/api/auth/login",
  })
    .then(res => {
      if (res.status === 200) {
        //this user is logged
        cb(res.data);
      } else {
        cb(false);
      }
    })
    .catch(e => {
      // woth error
      cb(false);
    });
};

module.exports.reqCreateUser = (opt, cb) => {
  let token = localStorage.getItem("token");
  axios({
    method: "POST",
    data: opt,
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
    url: "/api/auth/create",
  })
    .then(res => {
      if (res.status === 200) {
        if (cb) {
          cb(true);
        }
      } else {
        if (cb) {
          cb(false);
        }
      }
    })
    .catch(e => {
      cb(false);
    });
};

module.exports.reqUpdateUser = (opt, cb) => {
  let token = localStorage.getItem("token");
  axios({
    method: "POST",
    data: opt,
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
    url: "/api/auth/update",
  })
    .then(res => {
      if (res.status === 200) {
        if (cb) {
          cb(true);
        }
      } else {
        if (cb) {
          cb(false);
        }
      }
    })
    .catch(e => {
      cb(false);
    });
};

module.exports.reqRemoveUser = (opt, cb) => {
  let token = localStorage.getItem("token");
  axios({
    method: "POST",
    data: opt,
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
    url: "/api/auth/remove",
  })
    .then(res => {
      if (res.status === 200) {
        if (cb) {
          cb(true);
        }
      } else {
        if (cb) {
          cb(false);
        }
      }
    })
    .catch(e => {
      cb(false);
    });
};

module.exports.getUsers = (opt, cb) => {
  let token = localStorage.getItem("token");
  axios({
    method: "POST",
    data: opt,
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
    url: "/api/auth/get",
  })
    .then(res => {
      if (res.status === 200) {
        if (cb) {
          cb(res.data);
        }
      } else {
        if (cb) {
          cb(false);
        }
      }
    })
    .catch(e => {
      console.error(e);
    });
};

/****** data req */
module.exports.reqCreateData = (opt, cb) => {
  let token = localStorage.getItem("token");
  axios({
    method: "POST",
    data: opt,
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
    url: "/api/data/create",
  })
    .then(res => {
      if (res.status === 200) {
        if (cb) {
          cb(true);
        }
      } else {
        if (cb) {
          cb(false);
        }
      }
    })
    .catch(e => {
      if (cb) {
        cb(false);
      }
    });
};

module.exports.reqUpdateData = (opt, cb) => {
  let token = localStorage.getItem("token");
  axios({
    method: "POST",
    data: opt,
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
    url: "/api/data/update",
  })
    .then(res => {
      if (res.status === 200) {
        if (cb) {
          cb(true);
        }
      } else {
        if (cb) {
          cb(false);
        }
      }
    })
    .catch(e => {
      if (cb) {
        cb(false);
      }
    });
};

module.exports.reqGetData = (opt, cb) => {
  let token = localStorage.getItem("token");
  let firedCb = false;
  axios({
    method: "POST",
    data: opt,
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
    url: "/api/data/get",
  })
    .then(res => {
      if (res.status === 200) {
        if (cb && !firedCb) {
          firedCb = true;
          cb(res.data);
        }
      } else {
        if (cb && !firedCb) {
          firedCb = true;
          cb(false);
        }
      }
    })
    .catch(e => {
      cb(false);
    });
};

//update docsData
module.exports.reqSaveDocuments = (opt, cb) => {
  axios({
    method: "POST",
    data: opt,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    url: "/api/data/documents",
  })
    .then(res => {
      if (res.status === 200) {
        if (cb) {
          cb(res.data);
        }
      } else {
        if (cb) {
          cb(false);
        }
      }
    })
    .catch(e => {});
};

//update docsData
module.exports.reqSaveDocs = (opt, cb) => {
  let token = localStorage.getItem("token");
  axios({
    method: "POST",
    data: opt,
    headers: {
      Authorization: token,
      "Content-Type": "multipart/form-data",
    },
    url: "/api/data/upload",
  })
    .then(res => {
      if (res.status === 200) {
        if (cb) {
          cb(res.data);
        }
      } else {
        if (cb) {
          cb(false);
        }
      }
    })
    .catch(e => {});
};

module.exports.reqRemoveDocs = (opt, cb) => {
  let token = localStorage.getItem("token");
  axios({
    method: "POST",
    data: opt,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    url: "/api/data/deletedocs",
  })
    .then(res => {
      if (res.status === 200) {
        if (cb) {
          cb(true);
        }
      } else {
        if (cb) {
          cb(false);
        }
      }
    })
    .catch(e => {
      cb(false);
    });
};

module.exports.reqGetDocs = (opt, cb) => {
  let token = localStorage.getItem("token");
  axios({
    method: "POST",
    data: opt,
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
    url: "/api/data/getdocs",
  })
    .then(res => {
      if (res.status === 200) {
        if (cb) {
          cb(res.data);
        }
      } else {
        if (cb) {
          cb(false);
        }
      }
    })
    .catch(e => {
      cb(false);
    });
};

// getFields
module.exports.getFields = (opt, cb) => {
  let token = localStorage.getItem("token");

  axios({
    method: "GET",
    params: opt,
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
    url: "/api/data/getFields",
  })
    .then(res => {
      if (res.status === 200) {
        if (cb) {
          cb(res.data);
        }
      } else {
        if (cb) {
          cb(false);
        }
      }
    })
    .catch(e => {
      console.error(e, " as error ");
    });
};

axios.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    if (401 === err.response.status) {
      // user is not auth to do this

      if (isUserAuthenticated()) {
        deauthenticateUser();
        window.location = "/login";
      }
    }
  }
);
