import { getUsers, reqCreateUser, reqRemoveUser, reqUpdateUser } from "../../fn/req";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CloseIcon from "@material-ui/icons/Close";
import UpdateIcon from "@material-ui/icons/Update";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import store from "../../redux/store";
import { setAlert } from "../../redux/actions/menu";

const async = require("async");
const { Grid, Typography, Dialog, DialogContent, DialogTitle } = require("@material-ui/core");
const { Component } = require("react");
const { default: TableShow } = require("../data/dataShow/table");
const { default: Actions } = require("../Menu/Actions");
const { default: Header } = require("../Menu/Header");
const { default: Inputs } = require("../data/dataInput/inputs");

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      form: {},
      modal: {
        open: false,
      },
      actions: [
        {
          value: "add",
          alias: "Add User",
          action: this.addUser.bind(this),
          startIcon: <PersonAddIcon />,
        },
      ],
      heads: [
        {
          value: "name",
          alias: "Name",
          required: true,
        },
        {
          value: "username",
          alias: "Username",
          required: true,
        },
        {
          value: "password",
          alias: "Password",
          required: true,
          tableHide: true,
          type: "password",
        },
        {
          value: "type",
          alias: "Type",
          type: "select",
          trigger: true,
          variants: [
            {
              value: "admin",
              alias: "Admin",
            },
            {
              value: "agencie",
              alias: "Agencie",
            },
          ],
        },
        {
          value: "agencie",
          alias: "Agencie",
          type: "select",
          trigger: true,
          hidden: true,
          variants: [],
        },
        {
          value: "profile",
          alias: "Profile",
          type: "select",
          hidden: true,
          variants: [],
        },
        {
          value: "state",
          alias: "Active / Inactive",
          type: "boolean",
        },
      ],
    };
  }

  componentDidMount() {
    getUsers({ target: "users" }, (cb) => {
      this.setState({ users: cb });
    });
  }

  onClick(opt) {
    var form = {};
    Object.keys(opt).forEach((k) => {
      let value = opt[k];
      if (value && typeof value === "object" && !Array.isArray(value)) {
        // is object value
        value = opt[k].value;
      }
      form[k] = value;
    });

    async.parallel(
      [
        (c) =>
          getUsers({ target: "agencies" }, (cb) => {
            var variants = cb.map((v) => {
              return { value: v._id, alias: v.name };
            });
            c(null, variants);
          }),
        (c) =>
          getUsers({ target: "profiles", filter: { agencie: form.agencie } }, (cb) => {
            var variants = cb.map((v) => {
              return { value: v._id, alias: v.name };
            });
            c(null, variants);
          }),
      ],
      (e, r) => {
        var agenciesVariants = r[0];
        var profileVariants = r[1];

        var heads = this.state.heads.map((h) => {
          let nH = { ...h };
          if (nH.value === "agencie" || nH.value === "profile") {
            nH.hidden = form.type && form.type === "admin" ? true : false;
            nH.variants = nH.value === "agencie" ? agenciesVariants : profileVariants;
          }
          return nH;
        });

        this.setState({
          heads,
          form,
          modal: {
            open: true,
            title: "Edit user",
            actions: [
              {
                value: "close",
                alias: "Close",
                action: () => this.setState({ modal: { open: false }, form: {} }),
                startIcon: <CloseIcon />,
              },
              {
                value: "update",
                alias: "Update",
                color: "secondary",
                action: this.updateUser.bind(this),
                startIcon: <UpdateIcon />,
              },
              {
                value: "remove",
                alias: "Remove",
                color: "secondary",
                action: this.removeUser.bind(this),
                startIcon: <DeleteIcon />,
              },
            ],
          },
        });
      }
    );
  }

  saveUser(opt) {
    reqCreateUser({ form: this.state.form, target: "users" }, (cb) => {
      if (cb) {
        // update users
        getUsers({ target: "users" }, (cb) => {
          this.setState({ users: cb, modal: { open: false }, form: {} });
        });
      } else {
        // not update // alert user
      }
    });
  }

  updateUser(opt) {
    reqUpdateUser({ form: this.state.form, target: "users" }, (cb) => {
      if (cb) {
        // update users
        getUsers({ target: "users" }, (cb) => {
          this.setState({ users: cb, modal: { open: false }, form: {} });
        });
      } else {
        // not update, // alert user
      }
    });
  }

  removeUser(opt) {
    reqRemoveUser({ form: this.state.form, target: "users" }, (cb) => {
      if (cb) {
        // update users
        getUsers({ target: "users" }, (cb) => {
          this.setState({ users: cb, modal: { open: false }, form: {} });
        });
      } else {
        // not update, // alert user
        store.dispatch(
          setAlert({
            open: true,
            text: "Not able to remove user",
            severity: "error",
          })
        );
      }
    });
  }

  addUser(opt) {
    this.setState({
      modal: {
        open: true,
        title: "Add User",
        actions: [
          {
            value: "close",
            alias: "Close",
            action: () => this.setState({ modal: { open: false }, form: {} }),
            color: "secondary",
            startIcon: <CloseIcon />,
          },
          {
            value: "save",
            alias: "Save",
            color: "primary",
            action: this.saveUser.bind(this),
            startIcon: <SaveIcon />,
          },
        ],
      },
    });
  }

  handleChange(opt) {
    let field = opt.field;
    let form = { ...this.state.form };
    form[opt.target] = opt.value;
    this.setState({ form }, () => {
      if (field.trigger) {
        this.handleTrigger(opt);
      }
    });
  }

  handleTrigger(opt) {
    var target = opt.target;
    var value = opt.value;
    var form = { ...this.state.form };
    switch (target) {
      case "type":
        // get all agencies
        getUsers({ target: "agencies" }, (agencies) => {
          // show profile and agencie
          var heads = this.state.heads.map((h) => {
            let nH = { ...h };
            if (nH.value === "agencie") {
              nH.hidden = value === "admin" ? true : false;
              nH.variants = agencies.map((a) => {
                return { value: a._id, alias: a.name };
              });
              delete form[nH.value];
            }

            if (nH.value === "profile") {
              nH.hidden = value === "admin" ? true : false;
              delete form[nH.value];
            }

            return nH;
          });

          if (value === "admin") {
            // set form to null
            form.agencie = null;
            form.profile = null;
          }

          this.setState({ form, heads });
        });

        break;

      case "agencie":
        getUsers({ target: "profiles", filter: { agencie: value } }, (profiles) => {
          // show profile and agencie
          var heads = this.state.heads.map((h) => {
            let nH = { ...h };
            if (nH.value === "profile") {
              nH.hidden = false;
              nH.variants = profiles.map((a) => {
                return { value: a._id, alias: a.name };
              });
              delete form[nH.value];
            }
            return nH;
          });
          this.setState({ form, heads });
        });

        break;
      default:
        break;
    }
  }

  render() {
    let modal = this.state.modal;
    return (
      <Grid container>
        <Header tab={0} />
        <Grid item xs={12} className='my-3'>
          <Typography align='center' variant='h4' className='text-Pmain'>
            {"Users"}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <TableShow
            tableProps={{ size: "small" }}
            heads={this.state.heads}
            lines={this.state.users}
            onClick={this.onClick.bind(this)}
          />
        </Grid>
        <Grid item xs={12} className='p-2'>
          <Actions actions={this.state.actions} />
        </Grid>
        <Dialog
          open={modal.open}
          onClose={() => this.setState({ modal: { open: false }, form: {} })}
        >
          <DialogTitle>{modal.title}</DialogTitle>
          <DialogContent>
            <Inputs
              inputs={this.state.heads}
              values={this.state.form}
              handleChange={this.handleChange.bind(this)}
            />
          </DialogContent>
          <Grid className='m-2'>
            <Actions actions={modal.actions} />
          </Grid>
        </Dialog>
      </Grid>
    );
  }
}

export default Users;
