import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";

class Actions extends Component {
	render() {
		return (
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				className={this.props.className ? this.props.className : ""}
			>
				{this.props.actions
					? this.props.actions.map(a => {
							let props = {
								variant: "contained",
								onClick: a.action,
								startIcon: a.startIcon,
							};

							let className = "";
							if (a.className) {
								className += " " + a.className;
							}
							props.className = className;

							if (a.disabled) {
								props.disabled = true;
							}

							if (a.color) {
								props.color = a.color;
							}

							return (
								<Grid item key={a.value} className="m-1">
									<Button {...props}>{a.alias ? a.alias : a.value}</Button>
								</Grid>
							);
					  })
					: null}
			</Grid>
		);
	}
}
export default Actions;
