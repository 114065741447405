import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";
import store from "../../redux/store";
import { setAlert } from "../../redux/actions/menu";
import { Grid } from "@material-ui/core";

class Alerts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timeout: 2000,
			severity: "success",
			closed: false,
		};
	}

	componentDidMount() {
		this.unsubscribe = store.subscribe(() => {
			let state = store.getState();
			let alertState = state.menu.alert;
			let timeout = alertState.timeout
				? alertState.timeout
				: this.state.timeout;

			if (alertState.open && this.state.closed) {
				/// we have active alert, close this one;
				/// clear interval for prev
				clearInterval(this.interval);
				clearTimeout(this.timeout);

				this.timeout = setTimeout(() => {
					this.close();
				}, timeout);
			}

			if (alertState.open && !this.state.closed) {
				// we need to open alert
				this.setState({ closed: true }, () => {
					this.timeout = setTimeout(() => {
						this.close();
					}, timeout);
				});
			} else if (!alertState.open && this.state.closed) {
				// set closed to false
				this.setState({ closed: false });
			} else {
				// closed is false now on local state
				this.forceUpdate();
			}
		});
	}

	componentWillUnmount() {
		this.unsubscribe();
		clearInterval(this.interval);
		clearTimeout(this.timeout);
	}

	close() {
		this.setState({ closed: false }, () => {
			clearInterval(this.interval);
			store.dispatch(setAlert({ open: false }));
		});
	}

	render() {
		let state = store.getState();
		let alertState = state.menu.alert;

		if (alertState.open) {
			return (
				<Grid
					container
					style={{ zIndex: 10000, top: 100, right: 20 }}
					alignContent="flex-end"
					justifyContent="flex-end"
					className="position-fixed fadeIn"
				>
					<Grid item className="mx-2" xs={12} md={3}>
						<Alert
							onClose={this.close.bind(this)}
							severity={
								alertState.severity ? alertState.severity : this.state.severity
							}
							variant="filled"
						>
							{alertState.text}
						</Alert>
					</Grid>
				</Grid>
			);
		} else {
			return null;
		}
	}
}

export default Alerts;
