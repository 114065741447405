import {
  getFields,
  getUsers,
  reqCreateUser,
  reqRemoveUser,
  reqUpdateUser,
} from "../../fn/req";
import async from "async";
import { getTabs } from "../../fn/data";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CloseIcon from "@material-ui/icons/Close";
import UpdateIcon from "@material-ui/icons/Update";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import FilterListIcon from "@material-ui/icons/FilterList";

import store from "../../redux/store";
import { setAlert } from "../../redux/actions/menu";

const {
  Grid,
  Typography,
  Dialog,
  DialogContent,
} = require("@material-ui/core");
const { Component } = require("react");
const { default: TableShow } = require("../data/dataShow/table");
const { default: Actions } = require("../Menu/Actions");
const { default: Header } = require("../Menu/Header");
const { default: Inputs } = require("../data/dataInput/inputs");
const targetItems = ["participants", "accommodation", "jobs", "farms"];
const filterFields = ["filterTarget_", "field_", "operator_", "filterValue_"];
const excludingTabTypes = ["table", "history"];

class Profiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      form: {},
      modal: {
        open: false,
      },
      actions: [
        {
          value: "add",
          alias: "Add Profile",
          action: this.addProfile.bind(this),
          startIcon: <PersonAddIcon />,
        },
      ],
      heads: [
        {
          value: "name",
          alias: "Profile name",
          required: true,
        },
        {
          value: "description",
          alias: "Profile description",
          required: true,
          xs: 5,
          fullWidth: true,
        },
        {
          value: "agencie",
          alias: "Agencie",
          required: true,
          type: "select",
          variants: [],
        },
      ],

      farmsHeads: [
        {
          value: "showFarms",
          alias: "Show fields",
          type: "multiple",
          target: "farmsHeads",
          md: 3,
          trigger: true,
        },
        {
          value: "editFarms",
          alias: "Edit fields",
          type: "multiple",
          target: "farmsHeads",
          md: 3,
          trigger: true,
        },
        {
          value: "hideFarms",
          alias: "Hide fields",
          target: "farmsHeads",
          type: "multiple",
          md: 3,
          hidden: true,
          trigger: true,
        },
      ],
      accommodationHeads: [
        {
          value: "showAccommodations",
          alias: "Show fields",
          type: "multiple",
          target: "accommodationHeads",
          md: 3,
          trigger: true,
        },
        {
          value: "editAccommodations",
          alias: "Edit fields",
          type: "multiple",

          target: "accommodationHeads",
          md: 3,
          trigger: true,
        },
        {
          value: "hideAccommodations",
          alias: "Hide fields",

          target: "accommodationHeads",
          type: "multiple",
          md: 3,
          hidden: true,
          trigger: true,
        },
      ],
      jobsHeads: [
        {
          value: "showJobs",
          alias: "Show fields",
          type: "multiple",
          target: "jobsHeads",
          md: 3,
          trigger: true,
        },
        {
          value: "editJobs",
          alias: "Edit fields",
          type: "multiple",

          target: "jobsHeads",
          md: 3,
          trigger: true,
        },
        {
          value: "hideJobs",
          alias: "Hide fields",
          type: "multiple",
          hidden: true,
          target: "jobsHeads",
          md: 3,
          trigger: true,
        },
      ],
      participantsHeads: [
        {
          value: "showParticipants",
          alias: "Show fields",
          type: "multiple",
          md: 3,
          target: "participantsHeads",
          trigger: true,
        },
        {
          value: "editParticipants",
          alias: "Edit fields",
          type: "multiple",

          target: "participantsHeads",
          md: 3,
          trigger: true,
        },
        {
          value: "hideParticipants",
          alias: "Hide fields",
          type: "multiple",
          hidden: true,
          target: "participantsHeads",
          md: 3,
          trigger: true,
        },
      ],
      filters: [],
    };
  }

  componentDidMount() {
    getUsers({ target: "profiles" }, cb => {
      this.setState({ users: cb });
    });
  }

  async onClick(opt) {
    // set all Fields
    this.setAllFields(async () => {
      var form = {};
      Object.keys(opt).forEach(k => {
        var value = opt[k];

        if (value && typeof value === "object") {
          value = Array.isArray(value) ? opt[k] : opt[k].value;
        }
        form[k] = value;
      });
      var inputs = [...this.state.heads].map(i => {
        if (i.value === "agencie") {
          i.variants = [opt.agencie];
          i.disabled = true;
        }
        return i;
      });

      // reconstruct filters to object
      opt.filters.forEach(f => {
        Object.keys(f).forEach(k => {
          form[k] = f[k];
        });
      });

      for await (const [, f] of opt.filters.entries()) {
        let index = Object.keys(f)[0].split("_")[1];
        let tabs = getTabs({ target: f["filterTarget_" + index] });
        var requests = [];

        tabs.forEach(tab => {
          if (!excludingTabTypes.includes(tab.type)) {
            requests.push(c => {
              getFields(
                { target: f["filterTarget_" + index], tab: tab.value },
                cb => {
                  c(null, cb);
                }
              );
            });
          }
        });

        async.parallel(requests, (e, r) => {
          var fields = [];
          r.forEach(rr => {
            fields = fields.concat(rr.fields);
          });
          fields = fields.map(f => {
            f.hidden = false;
            return f;
          });
          this.addFilter({ index, update: true, fields });
        });

        // add variants
      }

      delete form.filters;

      var tempState = {
        form,
        modal: {
          open: true,
          title: "Edit profile",
          inputs,
          actions: [
            {
              value: "close",
              alias: "Close",
              action: () =>
                this.setState({
                  modal: { open: false },
                  form: {},
                  filters: [],
                }),
              startIcon: <CloseIcon />,
            },
            {
              value: "filter",
              alias: "Add filter",
              action: this.addFilter.bind(this),
              color: "secondary",
              startIcon: <FilterListIcon />,
            },
            {
              value: "update",
              alias: "Update",
              color: "secondary",
              action: this.updateUser.bind(this),
              startIcon: <UpdateIcon />,
            },

            {
              value: "remove",
              alias: "Remove",
              color: "secondary",
              action: this.removeUser.bind(this),
              startIcon: <DeleteIcon />,
            },
          ],
        },
      };

      // show or hide fields
      targetItems.forEach(target => {
        let fieldTarget = target + "Heads";
        let filterFields = this.state[fieldTarget].map(oldF => {
          let f = { ...oldF };
          if (f.value !== target) {
            f.variants = f.variants.map(oldV => {
              let v = { ...oldV };
              v.hidden = false;
              this.state[fieldTarget].forEach(ff => {
                if (
                  form[ff.value] &&
                  form[ff.value].length &&
                  form[ff.value].includes(v.value) &&
                  ff.value !== f.value
                ) {
                  v.hidden = true;
                }
              });

              return v;
            });
          }

          return f;
        });

        tempState[fieldTarget] = filterFields;
      });

      this.setState(tempState);
    });
  }

  saveUser(opt) {
    reqCreateUser({ form: this.state.form, target: "profiles" }, cb => {
      if (cb) {
        // update users
        getUsers({ target: "profiles" }, cb => {
          this.setState({
            users: cb,
            modal: { open: false },
            form: {},
            filters: [],
          });
        });
      } else {
        // not update // alert user
        store.dispatch(
          setAlert({
            text: "Check all necesary fields",
            open: true,
            severity: "error",
          })
        );
      }
    });
  }

  updateUser(opt) {
    reqUpdateUser({ form: this.state.form, target: "profiles" }, cb => {
      if (cb) {
        // update users
        getUsers({ target: "profiles" }, cb => {
          this.setState({
            users: cb,
            modal: { open: false },
            form: {},
            filters: [],
          });
        });
      } else {
        // not update, // alert user
      }
    });
  }

  removeUser(opt) {
    reqRemoveUser({ form: this.state.form, target: "profiles" }, cb => {
      if (cb) {
        // update users
        getUsers({ target: "profiles" }, cb => {
          this.setState({
            users: cb,
            modal: { open: false },
            form: {},
            filters: [],
          });
        });
      } else {
        // not update, // alert user
      }
    });
  }

  setAllFields = cb => {
    // get fields available before adding

    targetItems.forEach((t, i) => {
      let tabs = getTabs({ target: t });
      let fields = [];

      var requests = [];
      tabs.forEach(tab => {
        // only if tab is not table type
        if (!excludingTabTypes.includes(tab.type)) {
          requests.push(c => {
            getFields({ target: t, tab: tab.value }, cb2 => {
              c(null, cb2);
            });
          });
        }
      });

      async.parallel(requests, (e, r) => {
        fields = [];
        r.forEach(ff => (fields = fields.concat(ff.fields)));

        let tabHeads = [...this.state[t + "Heads"]];
        tabHeads = tabHeads.map(tabH => {
          tabH.variants = fields.map(oldF => {
            let f = { ...oldF };
            if (f.required) {
              f.alias = f.alias + " *";
            }
            f.hidden = false;
            return f;
          });

          return tabH;
        });

        this.setState({ [t + "Heads"]: tabHeads });

        if (i === targetItems.length - 1) {
          // this is the last one
          cb(true);
        }
      });
    });
  };

  addProfile(opt) {
    this.setAllFields(() => {
      var inputs = [...this.state.heads];

      getUsers({ target: "agencies" }, cb => {
        inputs = inputs.map(i => {
          if (i.value === "agencie") {
            // need new agencies
            i.disabled = false;
            i.variants = cb.map(v => {
              return {
                value: v._id,
                alias: v.name,
              };
            });
          }
          return i;
        });

        this.setState({
          modal: {
            open: true,
            title: "Add Profile",
            inputs,
            actions: [
              {
                value: "close",
                alias: "Close",
                action: () =>
                  this.setState({
                    modal: { open: false },
                    filters: [],
                    form: {},
                  }),
                color: "secondary",
                startIcon: <CloseIcon />,
              },
              {
                value: "filter",
                alias: "Add filter",
                action: this.addFilter.bind(this),
                color: "secondary",
                startIcon: <FilterListIcon />,
              },
              {
                value: "save",
                alias: "Save",
                color: "primary",
                action: this.saveUser.bind(this),
                startIcon: <SaveIcon />,
              },
            ],
          },
        });
      });
    });
  }

  removeFilter = opt => {
    var filters = [...this.state.filters].filter(f => {
      return f.id !== opt.id;
    });

    let form = { ...this.state.form };

    filterFields.forEach(f => {
      let target = f + opt.id;
      delete form[target];
    });

    this.setState({
      filters,
      form,
    });
  };

  addFilter = opt => {
    let index = -1;
    this.state.filters.forEach(f => {
      if (parseInt(f.id) > index) {
        index = f.id;
      }
    });

    // if we have index then we need to show all options
    // next index
    index = opt.update ? opt.index : parseInt(index) + 1;

    let inputs = [
      {
        value: "filterTarget_" + index,
        alias: "Filter target",
        type: "select",
        variants: targetItems.map(p => {
          return { value: p };
        }),
        trigger: true,
      },
      {
        value: "field_" + index,
        alias: "Field ",
        hidden: opt.update ? false : true,
        type: "select",
        variants: opt.fields ? opt.fields : [],
      },
      {
        value: "operator_" + index,
        alias: "Operator",
        hidden: opt.update ? false : true,
        type: "select",
        variants: [
          {
            value: "boolean",
            alias: "True/False",
          },
          {
            value: "contains",
            alias: "Contains",
          },
          {
            value: "notContains",
            alias: "Dos not contains",
          },
        ],
      },
      {
        value: "filterValue_" + index,
        hidden: opt.update ? false : true,
        alias: "Value",
      },
    ];

    var filters = this.state.filters.concat({
      inputs,
      id: index,
    });

    this.setState({
      filters,
    });
  };

  handleChange(opt) {
    let form = { ...this.state.form };
    let field = opt.field;
    form[opt.target] = opt.value;

    this.setState({ form }, () => {
      // check if we need to trigger
      if (field && field.trigger) {
        this.handleTrigger(opt);
      }
    });
  }

  handleTrigger(opt) {
    var field = opt.field;
    var fieldTarget = field.target;
    var target = opt.target;
    var filterForm = { ...this.state.form };

    switch (target) {
      case target.match(/filterTarget/)?.input:
        let index = field.value.split("_")[1];
        let tabs = getTabs({ target: opt.value });
        let fields = [];
        var requests = [];
        tabs.forEach(tab => {
          if (!excludingTabTypes.includes(tab.type)) {
            requests.push(c => {
              getFields({ target: opt.value, tab: tab.value }, cb => {
                c(null, cb);
              });
            });
          }
        });

        async.parallel(requests, (e, r) => {
          fields = [];
          r.forEach(ff => (fields = fields.concat(ff.fields)));

          let filters = this.state.filters.map(filter => {
            let newFilter = { ...filter };
            if (parseInt(filter.id) === parseInt(index)) {
              newFilter.inputs = newFilter.inputs.map(input => {
                let newInput = { ...input };
                newInput.hidden = false;
                if (newInput.value.split("_")[0] === "field") {
                  // set new variants
                  // show all fields
                  newInput.variants = fields.map(f => {
                    f.hidden = false;
                    return f;
                  });
                }

                if (newInput.value.split("_")[0] !== "filterTarget") {
                  /// remove selection from form
                  delete filterForm[newInput.value];
                }

                return newInput;
              });
            }

            return newFilter;
          });

          this.setState({
            filters,
            form: filterForm,
          });
        });

        break;

      default:
        let filterFields = this.state[fieldTarget].map(oldF => {
          let f = { ...oldF };
          if (f.value !== target) {
            f.variants = f.variants.map(oldV => {
              let v = { ...oldV };
              v.hidden = false;
              this.state[fieldTarget].forEach(ff => {
                if (
                  filterForm[ff.value] &&
                  filterForm[ff.value].length &&
                  filterForm[ff.value].includes(v.value) &&
                  ff.value !== f.value
                ) {
                  v.hidden = true;
                }
              });

              return v;
            });
          }

          return f;
        });

        this.setState({
          [fieldTarget]: filterFields,
        });
        break;
    }
  }

  render() {
    let modal = this.state.modal;
    return (
      <Grid container>
        <Header tab={1} />
        <Grid item xs={12} className="my-3">
          <Typography align="center" variant="h4" className="text-Pmain">
            {"Profiles"}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <TableShow
            tableProps={{ size: "small" }}
            heads={this.state.heads}
            lines={this.state.users}
            onClick={this.onClick.bind(this)}
          />
        </Grid>
        <Grid item xs={12} className="p-2">
          <Actions actions={this.state.actions} />
        </Grid>
        <Dialog
          open={modal.open}
          fullWidth
          maxWidth="lg"
          onClose={() =>
            this.setState({ modal: { open: false }, form: {}, filters: [] })
          }
        >
          <Typography variant="h4" align="center">
            {modal.title}
          </Typography>
          <DialogContent>
            <Inputs
              inputs={modal.inputs}
              values={this.state.form}
              handleChange={this.handleChange.bind(this)}
            />
            <Grid>
              <Typography variant="h5" align="center">
                {"Participants fields "}
              </Typography>
              <Grid
                style={{ height: 1, width: "80%" }}
                className="bg-Plight mx-auto my-1"
              ></Grid>
            </Grid>
            <Inputs
              inputs={this.state.participantsHeads}
              values={this.state.form}
              handleChange={this.handleChange.bind(this)}
            />
            <Grid>
              <Typography variant="h5" align="center">
                {"Farms fields "}
              </Typography>
              <Grid
                style={{ height: 1, width: "80%" }}
                className="bg-Plight mx-auto my-1"
              ></Grid>
            </Grid>

            <Inputs
              inputs={this.state.farmsHeads}
              values={this.state.form}
              handleChange={this.handleChange.bind(this)}
            />

            <Grid>
              <Typography variant="h5" align="center">
                {"Jobs fields "}
              </Typography>
              <Grid
                style={{ height: 1, width: "80%" }}
                className="bg-Plight mx-auto my-1"
              ></Grid>
            </Grid>

            <Inputs
              inputs={this.state.jobsHeads}
              values={this.state.form}
              handleChange={this.handleChange.bind(this)}
            />

            <Grid>
              <Typography variant="h5" align="center">
                {"Accommodation fields "}
              </Typography>
              <Grid
                style={{ height: 1, width: "80%" }}
                className="bg-Plight mx-auto my-1"
              ></Grid>
            </Grid>

            <Inputs
              inputs={this.state.accommodationHeads}
              values={this.state.form}
              handleChange={this.handleChange.bind(this)}
            />

            <Grid>
              <Typography variant="h5" align="center">
                {"Filters "}
              </Typography>
              <Grid
                style={{ height: 1, width: "80%" }}
                className="bg-Plight mx-auto my-1"
              ></Grid>
            </Grid>
            {this.state.filters.length
              ? this.state.filters.map(f => {
                  return (
                    <Grid
                      key={f.id}
                      item
                      container
                      xs={12}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Inputs
                          inputs={f.inputs}
                          values={this.state.form}
                          handleChange={this.handleChange.bind(this)}
                        />
                      </Grid>
                      <Grid item>
                        <RemoveCircleIcon
                          onClick={() =>
                            this.removeFilter({
                              id: f.id,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  );
                })
              : null}
          </DialogContent>
          <Grid className="m-2">
            <Actions actions={modal.actions} />
          </Grid>
        </Dialog>
      </Grid>
    );
  }
}

export default Profiles;
