import { Button, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import cfg from "../../../cfg.json";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";

function FieldsShow(props) {
  let fields = props.fields;
  let values = props.values;

  // declare States
  const [enlarge, setEnlarge] = useState({});
  const [itemContainer, setItemContainer] = useState(50);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState({});
  var itemC = React.createRef();

  var clickEnlarge = opt => {
    let actual = enlarge[opt.target];
    enlarge[opt.target] = actual ? false : true;
    setEnlarge({ ...enlarge });
    setPage(1);
  };

  var handlePage = opt => {
    let direction = opt.direction;
    let target = opt.target;
    let newPage = page;
    if (direction === "before") {
      newPage = page > 1 ? page - 1 : 1;
    } else {
      newPage = page < pages[target] ? page + 1 : pages[target];
    }
    setPage(newPage);
  };

  var handleDelete = opt => {
    // use props actions to delete doc
    // clear enlarge
    setEnlarge({});
    props.actions.deleteDocs({ target: opt.target, index: opt.index });
  };

  var handleDownload = opt => {
    // handle download click;
    let target = opt.target;
    let origin = opt.origin;
    let url;
    let f = document.createElement("a");
    switch (target) {
      case "pdf":
        url = `data:application/pdf;base64,${opt.data}`;
        break;

      default:
        url = `data:image/gif;base64,${opt.data}`;
        break;
    }
    f.href = url;
    f.setAttribute("src", url);
    f.setAttribute("download", origin);
    f.click();
  };

  React.useEffect(() => {
    if (itemC.current) {
      setItemContainer(itemC.current.clientWidth - 10);
    }
  }, [itemC]);

  var contextualActions = opt => {
    let target = opt.target;
    let style = { left: 10, top: 10, transparancy: 0.5 };
    switch (target) {
      case "pdf":
        // return actions for PDF
        return (
          <Grid
            className="bg-info border rounded position-absolute p-1 "
            style={style}
          >
            <Button
              className="m-1"
              onClick={e =>
                handlePage({ direction: "before", target: opt.origin })
              }
            >
              <NavigateBeforeIcon className="text-white" />
            </Button>
            {<span className="text-white">{" Pages "}</span>}
            <Button
              className="m-1"
              onClick={e =>
                handlePage({ direction: "next", target: opt.origin })
              }
            >
              <NavigateNextIcon className="text-white" />
            </Button>
            <Button
              className="m-1"
              onClick={e =>
                handleDownload({ target, data: opt.data, origin: opt.origin })
              }
            >
              <GetAppIcon className="text-white" />
            </Button>
            {!opt.disabled ? (
              <Button
                className="m-1"
                onClick={e =>
                  handleDelete({ target: opt.value, index: opt.index })
                }
              >
                <DeleteIcon className="text-white" />
              </Button>
            ) : null}
          </Grid>
        );

      default:
        return (
          <Grid
            className="bg-info border rounded position-absolute p-1 "
            style={style}
          >
            <Button
              className="m-1"
              onClick={e =>
                handleDownload({ target, data: opt.data, origin: opt.origin })
              }
            >
              <GetAppIcon className="text-white" />
            </Button>
            {!opt.disabled ? (
              <Button
                className="m-1"
                onClick={e =>
                  handleDelete({ target: opt.value, index: opt.index })
                }
              >
                <DeleteIcon className="text-white" />
              </Button>
            ) : null}
          </Grid>
        );
    }
  };

  if (fields) {
    return (
      <Grid container alignItems="center" justifyContent="center">
        {fields.map(f => {
          let type = f.type ? f.type : null;
          let value = values[f.value];
          let width = 150;
          let enlargedWidth = itemContainer;
          switch (type) {
            case "file":
              if (value && value.length) {
                return (
                  <Grid
                    item
                    ref={itemC}
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                    key={f.value}
                    className="rounded border border-Smain m-2 position-relative"
                  >
                    <Grid
                      className="d-block ml-1 position-absolute"
                      style={{ left: 0, top: -15 }}
                    >
                      <Typography
                        variant="caption"
                        className="d-inline bg-white px-2"
                      >
                        {f.alias ? f.alias : f.value}
                      </Typography>
                    </Grid>
                    {value.map((im, i) => {
                      let target = String(f.value + i);
                      switch (im.type) {
                        case "pdf":
                          let className = "w-100 rounded border";
                          if (enlarge[target]) {
                            className += " zoomOutCusros";
                          } else {
                            className += " zoomInCustor";
                          }

                          return (
                            <Grid
                              item
                              key={"pdf_" + i}
                              className="d-inline position-relative"
                            >
                              <Document
                                className={className}
                                file={`data:application/pdf;base64,${im.data}`}
                                onLoadSuccess={pdf =>
                                  setPages({
                                    ...pages,
                                    ...{
                                      [target]: pdf.numPages,
                                    },
                                  })
                                }
                                onClick={e =>
                                  clickEnlarge({
                                    target,
                                  })
                                }
                              >
                                <Page
                                  pageNumber={page}
                                  width={
                                    enlarge[target] ? enlargedWidth : width
                                  }
                                  className="d-inline"
                                />
                              </Document>
                              {enlarge[target]
                                ? contextualActions({
                                    target: "pdf",
                                    origin: target,
                                    value: f.value,
                                    data: im.data,
                                    disabled: f.disabled,
                                    index: im.originalIndex,
                                  })
                                : null}
                            </Grid>
                          );

                        default:
                          let className2 = "";
                          if (enlarge[target]) {
                            className2 += " zoomOutCusros";
                          } else {
                            className2 += " zoomInCustor";
                          }
                          return (
                            <Grid
                              item
                              key={"img_" + i}
                              className="d-inline position-relative"
                            >
                              <Grid
                                className={className2}
                                onClick={e =>
                                  clickEnlarge({
                                    target: f.value + i,
                                  })
                                }
                              >
                                <img
                                  className="rounded border"
                                  style={{
                                    width: enlarge[f.value + i]
                                      ? enlargedWidth
                                      : width,
                                    height: "100%",
                                  }}
                                  src={`data:image/gif;base64,${im.data}`}
                                  alt=""
                                />
                              </Grid>
                              {enlarge[target]
                                ? contextualActions({
                                    target: "img",
                                    origin: target,
                                    data: im.data,
                                    value: f.value,
                                    disabled: f.disabled,
                                    index: im.originalIndex,
                                  })
                                : null}
                            </Grid>
                          );
                      }
                    })}
                  </Grid>
                );
              } else {
                return null;
              }
            default:
              //default case
              if (f.type === "date") {
                // value is date
                value = value ? moment(value).format(cfg.dateFormat) : null;
              }

              if (f.type === "datetime") {
                // value is datetime
                value = value ? moment(value).format(cfg.dateTimeFormat) : null;
              }

              if (f.type === "boolean") {
                // values is yes or no
                value = value ? "YES" : "NO";
              }

              if (value && Array.isArray(value)) {
                // value as array
                value = value.map(v => {
                  if (typeof v === "object") {
                    return v[f.alternativeValue]
                      ? v[f.alternativeValue]
                      : v.alias
                      ? v.alias
                      : v._id;
                  } else {
                    return v;
                  }
                });

                value = value.join(", ");
              }

              if (value && typeof value === "object") {
                value = value[f.alternativeValue]
                  ? value[f.alternativeValue]
                  : value._id;
              }

              let valueClassName = "";
              let valueOnClick = null;

              // if field is link then activate as btn
              if (f.link) {
                valueClassName += " handCursor text-primary textUnderline";

                // navigate to new location

                valueOnClick = () =>
                  props.actions.selectItem({
                    item: values[f.value],
                    field: f,
                  });
              }

              if (value) {
                return (
                  <Grid
                    key={f.value}
                    item
                    className="rounded border border-Smain m-2 px-2 d-block positon-relative"
                    style={{ minWidth: 150 }}
                  >
                    <Grid className="d-block ml-1" style={{ marginTop: -15 }}>
                      <Typography
                        variant="caption"
                        className="d-inline bg-white text-Sdark px-2"
                      >
                        {f.alias ? f.alias : f.value}
                      </Typography>
                    </Grid>
                    <Typography
                      variant="h5"
                      align="center"
                      className={valueClassName}
                      onClick={valueOnClick}
                    >
                      {value}
                    </Typography>
                  </Grid>
                );
              } else {
                return null;
              }
          }
        })}
      </Grid>
    );
  } else {
    return null;
  }
}
export default FieldsShow;
