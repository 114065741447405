exports.authenticateUser = opt => {
	let fields = ["token", "name", "type"];

	fields.forEach(f => {
		localStorage.setItem(f, opt[f]);
	});
};

exports.isUserAuthenticated = () => {
	return localStorage.getItem("token") !== null;
};

exports.deauthenticateUser = () => {
	// remove token from
	localStorage.removeItem("token");
	// clear all menu state and data state
};
