let initState = {
	alert: { open: false },
};

export default function menu(state = initState, action) {
	let newState = { ...state };
	switch (action.type) {
		case "SET_ALERT":
			newState.alert = action.payload;
			return newState;
		case "SET_FILTER":
			newState.filter = action.payload;
			return newState;
		case "SET_ITEM":
			newState.item = action.payload;
			return newState;
		default:
			return state;
	}
}
