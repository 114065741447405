import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import Header from "../Menu/Header";
import Actions from "../Menu/Actions";
import Inputs from "../data/dataInput/inputs";
import { getFields, reqGetData, reqSaveDocuments } from "../../fn/req";
import store from "../../redux/store";
import { setAlert, setItem } from "../../redux/actions/menu";
import { Redirect } from "react-router-dom";
import TableShow from "../data/dataShow/table";
import { getItem, getTabs } from "../../fn/data";

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      form: {},
    };
  }

  saveDocs = () => {
    const userAgent = window.navigator.userAgent;
    const files = this.state.form.docs;
    var fData = new FormData();
    fData.append("userAgent", userAgent);

    fData.append("target", "documents");

    for (let i = 0; i < files.length; i++) {
      fData.append("file", files[i]);
    }

    reqSaveDocuments(fData, cb => {
      this.setState({
        modal: false,
      });
      if (cb) {
        // documents are saved
        store.dispatch(
          setAlert({
            text: "Fișierele au fost încărcate",
            open: true,
            severity: "success",
          })
        );
      } else {
        store.dispatch(
          setAlert({
            text: "Nu am reușit să încărcăm, vă rugăm reîncercați",
            open: true,
            severity: "error",
          })
        );
      }
    });
  };

  openModal = () => {
    this.setState({
      modal: {
        title: "Încarcă documente",
        actions: [
          {
            value: "close",
            alias: "Renunță",
            color: "secondary",
            action: () => this.setState({ modal: false }),
          },
          {
            value: "save",
            alias: "Salvează",
            color: "secondary",
            action: this.saveDocs,
          },
        ],
        inputs: [
          {
            value: "docs",
            alias: "Alege documentele",
            type: "file",
            limit: 10,
            multiple: true,
            xs: 12,
            accept: "image/png,image/jpeg,application/pdf",
          },
        ],
      },
    });
  };

  handleChange(opt) {
    let form = { ...this.state.form };
    form[opt.target] = opt.value;
    this.setState({ form });
  }

  render() {
    const modal = this.state.modal;
    return (
      <Grid container>
        <Header tab={2} />
        <Grid item xs={12}>
          <Typography variant="h4" align="center" className="my-3 text-Pmain">
            Încărcare Documente
          </Typography>
          <Dialog
            open={modal ? true : false}
            onClose={() => this.setState({ modal: false })}
            fullWidth
            maxWidth={"md"}
          >
            <DialogTitle>{modal.title}</DialogTitle>
            {modal.text ? (
              <Typography variant="h4" align="center">
                {modal.text}
              </Typography>
            ) : null}
            <DialogContent>
              <Inputs
                inputs={modal.inputs}
                values={this.state.form}
                handleChange={this.handleChange.bind(this)}
              />
            </DialogContent>
            <Grid className="m-2">
              <Actions actions={modal.actions} />
            </Grid>
          </Dialog>
          <Actions
            actions={[
              {
                value: "upload",
                alias: "Încarcă documente",
                action: this.openModal.bind(this),
                color: "primary",
              },
            ]}
          />
        </Grid>
      </Grid>
    );
  }
}

class DocumentsShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      tableHeads: [],
    };
  }

  componentDidMount() {
    // get docs on mount
    reqGetData({ target: "documents" }, data => {
      this.setState({
        data,
      });
    });

    // get fields
    getFields(
      {
        target: "documents",
        tab: "overview",
      },
      cb => {
        var tableHeads = cb.fields;
        tableHeads.push({
          value: "actions",
          alias: "Actions",
          type: "actions",
          variants: [
            {
              value: "view",
              alias: "View",
              icon: "visibility",
              action: opt => this.viewItem({ id: opt }),
            },
          ],
        });
        this.setState({ tableHeads });
      }
    );
  }

  viewItem = opt => {
    store.dispatch(
      setItem({
        tab: 5,
        type: "view",
        titleFields: ["timeStamp"],
        data: getItem({ data: this.state.data, id: opt.id }),
        origin: "showdocs",
        reference: "documents",
        disablePdf: true,
        tabs: getTabs({ target: "documents" }),
      })
    );
    this.setState({
      redirect: true,
      redirectTo: "/item",
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectTo} />;
    } else {
      const data = this.state.data;
      return (
        <Grid container>
          <Header tab={5} />
          <Grid item xs={12}>
            <Typography variant="h4" align="center" className="my-3 text-Pmain">
              Documents
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <TableShow
              tableProps={{ size: "small" }}
              heads={this.state.tableHeads}
              lines={data ? data : []}
            />
          </Grid>
        </Grid>
      );
    }
  }
}

export { Documents, DocumentsShow };
