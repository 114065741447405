export function setAlert(payload) {
	return { type: "SET_ALERT", payload };
}
export function setFilter(payload) {
	return { type: "SET_FILTER", payload };
}

export function setItem(payload) {
	return { type: "SET_ITEM", payload };
}
