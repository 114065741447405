import { getUsers, reqCreateUser, reqRemoveUser, reqUpdateUser } from "../../fn/req";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CloseIcon from "@material-ui/icons/Close";
import UpdateIcon from "@material-ui/icons/Update";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";

import store from "../../redux/store";
import { setAlert } from "../../redux/actions/menu";

const { Grid, Typography, Dialog, DialogContent, DialogTitle } = require("@material-ui/core");
const { Component } = require("react");
const { default: TableShow } = require("../data/dataShow/table");
const { default: Actions } = require("../Menu/Actions");
const { default: Header } = require("../Menu/Header");
const { default: Inputs } = require("../data/dataInput/inputs");

class Agencies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      form: {},
      modal: {
        open: false,
      },
      actions: [
        {
          value: "add",
          alias: "Add Agencie",
          action: this.addUser.bind(this),
          startIcon: <PersonAddIcon />,
        },
      ],
      heads: [
        {
          value: "name",
          alias: "Name",
          xs: 12,
          fullWidth: true,
          required: true,
        },
        {
          value: "state",
          alias: "Agencie state",
          xs: 4,
          fullWidth: true,
          type: "boolean",
        },
        {
          value: "alocatedItems",
          alias: "Only alocated items",
          type: "boolean",
          xs: 7,
          fullWidth: true,
        },
        {
          value: "type",
          alias: "Agencie type",
          type: "select",
          xs: 12,
          variants: [
            {
              value: "employer",
              alias: "HR agency",
            },
            {
              value: "recruitment",
              alias: "Recruiting agency",
            },
          ],
        },
        {
          value: "description",
          alias: "Description",
          tableHide: true,
          fullWidth: true,
          xs: 12,
          rows: 3,
          multiline: true,
        },
      ],
    };
  }

  componentDidMount() {
    getUsers({ target: "agencies" }, cb => {
      this.setState({ users: cb });
    });
  }

  onClick(opt) {
    this.setState({
      form: opt,
      modal: {
        open: true,
        title: "Edit user",
        inputs: [...this.state.heads],
        actions: [
          {
            value: "close",
            alias: "Close",
            action: () => this.setState({ modal: { open: false }, form: {} }),
            startIcon: <CloseIcon />,
          },
          {
            value: "update",
            alias: "Update",
            color: "secondary",
            action: this.updateUser.bind(this),
            startIcon: <UpdateIcon />,
          },
          {
            value: "remove",
            alias: "Remove",
            color: "secondary",
            action: this.removeUser.bind(this),
            startIcon: <DeleteIcon />,
          },
        ],
      },
    });
  }

  saveUser(opt) {
    reqCreateUser({ form: this.state.form, target: "agencies" }, cb => {
      if (cb) {
        // update users
        getUsers({ target: "agencies" }, cb => {
          this.setState({ users: cb, modal: { open: false }, form: {} });
        });
      } else {
        // not update // alert user
        store.dispatch(
          setAlert({
            text: "Check all necesary fields",
            open: true,
            severity: "error",
          })
        );
      }
    });
  }

  updateUser(opt) {
    reqUpdateUser({ target: "agencies", form: this.state.form }, cb => {
      if (cb) {
        // update users
        getUsers({ target: "agencies" }, cb => {
          this.setState({ users: cb, modal: { open: false }, form: {} });
        });
      } else {
        // not update, // alert user
      }
    });
  }

  removeUser(opt) {
    reqRemoveUser({ target: "agencies", form: this.state.form }, cb => {
      if (cb) {
        // update users
        getUsers({ target: "agencies" }, cb => {
          this.setState({ users: cb, modal: { open: false }, form: {} });
        });
      } else {
        // not update, // alert user
      }
    });
  }

  addUser(opt) {
    this.setState({
      modal: {
        open: true,
        title: "Add Agencie",
        inputs: [...this.state.heads],
        form: {},
        actions: [
          {
            value: "close",
            alias: "Close",
            action: () => this.setState({ modal: { open: false }, form: {} }),
            color: "secondary",
            startIcon: <CloseIcon />,
          },
          {
            value: "save",
            alias: "Save",
            color: "primary",
            action: this.saveUser.bind(this),
            startIcon: <SaveIcon />,
          },
        ],
      },
    });
  }

  handleChange(opt) {
    let form = { ...this.state.form };
    form[opt.target] = opt.value;
    this.setState({ form });
  }

  render() {
    let modal = this.state.modal;
    return (
      <Grid container>
        <Header tab={2} />
        <Grid item xs={12} className='my-3'>
          <Typography align='center' variant='h4' className='text-Pmain'>
            {"Agencies"}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <TableShow
            tableProps={{ size: "small" }}
            heads={this.state.heads}
            lines={this.state.users}
            onClick={this.onClick.bind(this)}
          />
        </Grid>
        <Grid item xs={12} className='p-2'>
          <Actions actions={this.state.actions} />
        </Grid>
        <Dialog
          open={modal.open}
          onClose={() => this.setState({ modal: { open: false }, form: {} })}
        >
          <DialogTitle>{modal.title}</DialogTitle>
          <DialogContent>
            <Inputs
              inputs={modal.inputs}
              values={this.state.form}
              handleChange={this.handleChange.bind(this)}
            />
          </DialogContent>
          <Grid className='m-2'>
            <Actions actions={modal.actions} />
          </Grid>
        </Dialog>
      </Grid>
    );
  }
}

export default Agencies;
