import React from "react";
import cfg from "../../../cfg.json";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

function renderValue(opt) {
  let variants = opt.input.variants;
  let value = opt.value;
  let renderedValue = "";
  variants.forEach(v => {
    if (value.includes(v.value)) {
      renderedValue += (v.alias ? v.alias : v.value) + ", ";
    }
  });
  return renderedValue;
}

function Inputs(props) {
  let inputs = props.inputs;
  let values = props.values;
  let opt = props.opt;
  let className = "m-1";

  if (opt && opt.className) {
    className += " " + opt.className;
  }

  if (inputs) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        {inputs.map(i => {
          let value = values ? values[i.value] : null;
          let type = i.type;

          if (!i.hidden) {
            switch (type) {
              case "select":
                return (
                  <Grid
                    item
                    key={i.value}
                    className={className + " p-2"}
                    xs={i.xs ? i.xs : null}
                    md={i.md ? i.md : null}
                    style={{ width: 200 }}
                  >
                    <FormControl fullWidth variant="outlined">
                      <InputLabel shrink id="idLable" className="bg-white px-2">
                        {i.alias ? i.alias : i.value}
                      </InputLabel>
                      <Select
                        labelId="idLable"
                        disabled={i.disabled ? true : false}
                        required={i.required ? true : false}
                        multiple={i.multiple ? true : false}
                        value={value ? value : ""}
                        displayEmpty
                        fullWidth
                        variant="outlined"
                        onChange={e => {
                          props.handleChange({
                            target: i.value,
                            value: e.target.value,
                            trigger: i.trigger,
                            field: i,
                          });
                        }}
                      >
                        {i.variants && i.variants.length
                          ? i.variants.map(v => {
                              if (!v.hidden) {
                                return (
                                  <MenuItem value={v.value} key={v.value}>
                                    {v.alias ? v.alias : v.value}
                                  </MenuItem>
                                );
                              } else {
                                return null;
                              }
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                );
              case "multiple":
                return (
                  <Grid
                    item
                    key={"multiple" + i.value}
                    className={className + " p-2"}
                    xs={i.xs ? i.xs : null}
                    md={i.md ? i.md : null}
                    style={{ width: 200 }}
                  >
                    <FormControl fullWidth variant="outlined">
                      <InputLabel shrink id="idLable" className="bg-white px-2">
                        {i.alias ? i.alias : i.value}
                      </InputLabel>
                      <Select
                        labelId="idLable"
                        disabled={i.disabled ? true : false}
                        required={i.required ? true : false}
                        value={value ? value : []}
                        multiple
                        renderValue={() => renderValue({ value, input: i })}
                        variant="outlined"
                      >
                        {i.variants && i.variants.length
                          ? i.variants.map(v => {
                              let checked = false;
                              if (value && value.includes(v.value)) {
                                // this exists
                                checked = true;
                              }
                              var handleChange = () => {
                                let values = value;
                                if (!values) {
                                  // no values

                                  values = [v.value];
                                } else {
                                  // we have values;
                                  if (values.includes(v.value)) {
                                    // we have, need to splice
                                    let index = values.indexOf(v.value);
                                    values.splice(index, 1);
                                  } else {
                                    values.push(v.value);
                                  }
                                }

                                props.handleChange({
                                  target: i.value,
                                  value: values,
                                  field: i,
                                });
                              };

                              if (!v.hidden) {
                                return (
                                  <MenuItem
                                    key={v.alias ? v.alias : v.value}
                                    value={value}
                                  >
                                    <Checkbox
                                      checked={checked}
                                      onChange={handleChange}
                                    />
                                    <ListItemText
                                      primary={v.alias ? v.alias : v.value}
                                      onClick={handleChange}
                                    />
                                  </MenuItem>
                                );
                              } else {
                                return null;
                              }
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                );
              case "boolean":
                return (
                  <Grid
                    item
                    key={i.value}
                    className={className + " p-2 border border-gray"}
                    xs={i.xs ? i.xs : null}
                    md={i.md ? i.md : null}
                    style={{ width: 200 }}
                  >
                    <Grid container item xs={12}>
                      <Grid item>
                        <Switch
                          checked={value ? value : false}
                          disabled={i.disabled ? true : false}
                          name={i.alias ? i.alias : i.value}
                          color="primary"
                          onChange={e => {
                            props.handleChange({
                              target: i.value,
                              value: e.target.checked,
                              field: i,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item className="my-auto">
                        <Typography variant="body1">{i.alias}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              case "date":
                return (
                  <Grid
                    item
                    key={i.value}
                    className={className + " p-2"}
                    xs={i.xs ? i.xs : null}
                    md={i.md ? i.md : null}
                    style={{ width: 200 }}
                  >
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      className="m-0"
                    >
                      <KeyboardDatePicker
                        id={String("date_" + i.value)}
                        disabled={i.disabled ? true : null}
                        margin="normal"
                        inputVariant="outlined"
                        format={cfg.dateFormat}
                        autoOk={true}
                        required={i.required ? true : false}
                        label={i.alias}
                        value={value ? value : null}
                        onChange={e =>
                          props.handleChange({
                            target: i.value,
                            value: e,
                            field: i,
                          })
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                );
              case "autocomplete":
                if (!value) {
                  value = i.multiple ? [] : null;
                }

                return (
                  <Grid
                    item
                    key={i.value}
                    className={className}
                    xs={i.xs ? i.xs : null}
                    md={i.md ? i.md : null}
                    style={{ width: 200 }}
                  >
                    <Autocomplete
                      id={i.value}
                      getOptionLabel={option =>
                        option.alias ? option.alias : String(option.value)
                      }
                      options={i.variants}
                      disabled={i.disabled ? true : null}
                      multiple={i.multiple ? true : null}
                      getOptionSelected={(option, value) =>
                        value ? option.value === value.value : false
                      }
                      value={value}
                      onChange={(e, b) => {
                        props.handleChange({
                          e,
                          field: i,
                          target: i.value,
                          value: b,
                          type: "select",
                        });
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          color="secondary"
                          id={i.value}
                          label={i.alias}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                );
              case "file":
                return (
                  <Grid
                    item
                    key={i.value}
                    className={className + " p-2"}
                    xs={i.xs ? i.xs : null}
                    md={i.md ? i.md : null}
                    style={{ width: 400 }}
                  >
                    <TextField
                      onChange={e =>
                        props.handleChange({
                          target: i.value,
                          value: e.target.files,
                          type: i.type,
                          limit: i.limit,
                          field: i,
                        })
                      }
                      label={i.alias ? i.alias : i.value}
                      disabled={i.disabled ? i.disabled : null}
                      required={i.required ? true : false}
                      id={i.value}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        multiple: i.multiple ? true : false,
                        accept: i.accept ? i.accept : "",
                        max: i.limit ? i.limit : 10,
                      }}
                      type={i.type ? i.type : "text"}
                      className="w-100"
                      variant="outlined"
                    />
                  </Grid>
                );
              default:
                // if no type return default
                return (
                  <Grid
                    item
                    key={i.value}
                    className={className + " border-gray"}
                    xs={i.xs ? i.xs : null}
                    md={i.md ? i.md : null}
                  >
                    <TextField
                      value={value ? value : ""}
                      id={i.value}
                      required={i.required ? true : false}
                      type={i.type ? i.type : "text"}
                      multiline={i.rows ? true : false}
                      fullWidth={i.fullWidth ? true : false}
                      rows={i.rows ? i.rows : null}
                      onChange={e =>
                        props.handleChange({
                          field: i,
                          target: i.value,
                          value:
                            i.type && i.type === "number"
                              ? parseFloat(e.target.value)
                              : e.target.value,
                        })
                      }
                      variant="outlined"
                      label={i.alias ? i.alias : i.value}
                      disabled={i.disabled ? i.disabled : null}
                    />
                  </Grid>
                );
            }
          } else {
            return null;
          }
        })}
      </Grid>
    );
  } else {
    return null;
  }
}

export default Inputs;
