import Actions from "../../Menu/Actions";
import Inputs from "../dataInput/inputs";
import React, { useState, useEffect } from "react";

const {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} = require("@material-ui/core");

const { IconButton } = require("@material-ui/core");

const {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  FirstPage,
  LastPage,
  Visibility,
  Edit,
  Delete,
  FilterList,
} = require("@material-ui/icons");
const cfg = require("../../../cfg.json");
const moment = require("moment");

function TableShow(props) {
  const heads = props.heads;
  const onClick = props.onClick;
  const headFilters = props.headFilters;

  const [f, setF] = useState({ open: false });
  const [d, setD] = useState(false);
  const [lines, setLines] = useState(null);
  const [count, setCount] = useState(props.lines.length);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChange = opt => {
    let flt = { ...f };
    if (!flt.conditions) {
      flt.conditions = {};
    }
    flt.conditions[opt.target] = opt.value;
    setF(flt);
  };

  useEffect(() => {
    let conditions = f.conditions;
    let operator = conditions ? conditions.operator : null;
    let value = conditions ? conditions.value : null;
    if (operator && value) {
      let field = String(f.h.value);
      var ll;
      switch (operator) {
        case "=":
          ll = props.lines.filter(l => {
            if (
              String(l[field]).toLowerCase() === String(value).toLowerCase()
            ) {
              return true;
            } else {
              return false;
            }
          });
          setLines(ll);

          break;
        case "?":
          ll = props.lines.filter(l => {
            if (
              String(l[field])
                .toLowerCase()
                .includes(String(value).toLowerCase())
            ) {
              return true;
            } else {
              return false;
            }
          });
          setLines(ll);
          break;
        case "<>":
          ll = props.lines.filter(l => {
            if (
              !String(l[field])
                .toLowerCase()
                .includes(String(value).toLowerCase())
            ) {
              return true;
            } else {
              return false;
            }
          });
          setLines(ll);
          break;
        case ">":
          ll = props.lines.filter(l => {
            if (parseFloat(l[field]) > parseFloat(value)) {
              return true;
            } else {
              return false;
            }
          });
          setLines(ll);
          break;
        case "<":
          ll = props.lines.filter(l => {
            if (parseFloat(l[field]) < parseFloat(value)) {
              return true;
            } else {
              return false;
            }
          });
          setLines(ll);
          break;
        default:
          break;
      }
    }

    if (!value) {
      setLines(null);
    }
  }, [f]);

  const constructFilter = h => {
    // filter to show next to text
    if (
      headFilters &&
      h.type !== "actions" &&
      !h.alternativeValue &&
      h.type !== "date"
    ) {
      let color = "secondary";

      if (f.h && f.h.value === h.value && f.conditions && f.conditions.value) {
        // this is the active filter
        color = "error";
      }

      return (
        <FilterList
          fontSize="small"
          color={color}
          className="handCursor mx-1"
          onClick={() => {
            setD(true);

            var conditions = { operator: "=" };
            if (f.h && f.h.value === h.value) {
              // replicate conditions
              conditions = { ...f.conditions };
            }
            setF({
              h,
              conditions,
              inputs: [
                {
                  value: "operator",
                  alias: "Operator",
                  type: "select",
                  variants: [
                    { value: "=", alias: "Equal" },
                    { value: "?", alias: "Containts" },
                    { value: "<>", alias: "Not contains" },
                    { value: ">", alias: "Greater then" },
                    { value: "<", alias: "Smaller then" },
                  ],
                },
                {
                  value: "value",
                  alias: "Value",
                },
              ],
              actions: [
                {
                  value: "close",
                  alias: "Close",
                  action: () => setD(false),
                },
                {
                  value: "apply",
                  alias: "Apply",
                  action: () => setD(false),
                },
                {
                  value: "clear",
                  alias: "Clear Filter",
                  action: () => {
                    setD(false);
                    setF({ open: false, tile: "Clear" });
                  },
                },
              ],
            });
          }}
        />
      );
    } else {
      return null;
    }
  };

  var showHeads = opt => {
    let he = opt.heads;
    if (he && he.length) {
      let cells = [];
      he.forEach((h, i) => {
        if (!h.tableHide) {
          cells.push(
            <TableCell key={i}>
              <Grid container>
                <Grid item>{h.alias ? h.alias : h.value}</Grid>
                <Grid item>{constructFilter(h)}</Grid>
              </Grid>
            </TableCell>
          );
        }
      });
      return cells;
    } else {
      return null;
    }
  };

  var showBody = opt => {
    let li = opt.lines;
    let he = opt.heads;

    var generateCell = l => {
      let cells = [];
      he.forEach((h, j) => {
        let value = l[h.value];
        if (h.type === "boolean") {
          value = value ? "Active" : "Inactive";
        }

        if (h.type === "date") {
          value = moment(value).format(cfg.dateFormat);
        }

        if (h.type === "datetime") {
          value = moment(value).format(cfg.dateTimeFormat);
        }

        if (value && typeof value === "object") {
          if (value.alias) {
            // try alias
            value = value.alias;
          } else {
            value = value[h.alternativeValue]
              ? value[h.alternativeValue]
              : value._id;
          }
        }

        if (h.type === "actions") {
          // this is actions Table Cell
          cells.push(
            <TableCell key={j}>
              {h.variants.map(v => {
                switch (v.icon) {
                  case "visibility":
                    return (
                      <Visibility
                        key={v.value}
                        className="handCursor text-Pmain mx-1"
                        onClick={v.action ? () => v.action(l._id) : null}
                      />
                    );
                  case "edit":
                    return (
                      <Edit
                        key={v.value}
                        className="handCursor text-Pmain mx-1"
                        onClick={v.action ? () => v.action(l._id) : null}
                      />
                    );
                  case "delete":
                    if (props.allowDelete) {
                      return (
                        <Delete
                          key={v.value}
                          className="handCursor text-Pmain mx-1"
                          onClick={v.action ? () => v.action(l._id) : null}
                        />
                      );
                    } else return null;
                  default:
                    return null;
                }
              })}
            </TableCell>
          );
        } else if (!h.tableHide) {
          cells.push(<TableCell key={j}>{value}</TableCell>);
        }
      });
      return cells;
    };

    if (li && li.length && he && he.length) {
      let start = (page + 1) * rowsPerPage - rowsPerPage;
      let end = start + rowsPerPage;
      end = end === -1 ? li.length : end;
      let lin = [];
      li.forEach((l, i) => {
        if (i < end && i >= start) {
          lin.push(
            <TableRow hover key={i} onClick={onClick ? () => onClick(l) : null}>
              {generateCell(l)}
            </TableRow>
          );
        }
      });
      return lin;
    } else {
      return null;
    }
  };

  function TablePaginationActions(p) {
    const handleFirstPageButtonClick = event => {
      setPage(0);
    };

    const handleBackButtonClick = event => {
      setPage(page - 1);
    };

    const handleNextButtonClick = event => {
      setPage(page + 1);
    };

    const handleLastPageButtonClick = event => {
      setPage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    if (count) {
      return (
        <Grid className="mx-1 d-flex">
          <IconButton
            size="small"
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            area-label="first page"
          >
            <FirstPage fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            <KeyboardArrowLeft fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            <KeyboardArrowRight fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
          >
            <LastPage fontSize="small" />
          </IconButton>
        </Grid>
      );
    } else {
      return null;
    }
  }

  return (
    <Grid className="p-1">
      <TableContainer component={Paper} className="border border-Smain">
        <Table {...props.tableProps}>
          <TableHead className="bg-light">
            <TableRow>{showHeads({ heads })}</TableRow>
          </TableHead>
          <TableBody>
            {showBody({ lines: lines ? lines : props.lines, heads })}
          </TableBody>
          <TableFooter>
            <TableRow>
              {count ? (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={e => {
                    setPage(parseInt(e.target.value, 10));
                  }}
                  onRowsPerPageChange={e => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              ) : null}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Dialog
        open={d}
        onClose={() => {
          let flt = { ...f };
          flt.open = false;
          setF(flt);
        }}
      >
        <DialogTitle>{f.h ? f.h.alias : null}</DialogTitle>
        <DialogContent>
          <Inputs
            inputs={f.inputs ? [...f.inputs] : []}
            values={f.conditions ? { ...f.conditions } : {}}
            handleChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Actions actions={f.actions} />
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default TableShow;
