import { getFields, reqCreateData, reqGetData } from "../../fn/req";

import store from "../../redux/store";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import { dataFilter, getTabs, getItem, updateData } from "../../fn/data";
import { setAlert, setItem } from "../../redux/actions/menu";
import { Redirect } from "react-router";

const { Grid, Typography, Dialog, DialogContent, DialogTitle } = require("@material-ui/core");
const { Component } = require("react");
const { default: TableShow } = require("../data/dataShow/table");
const { default: Actions } = require("../Menu/Actions");
const { default: Header } = require("../Menu/Header");
const { default: Inputs } = require("../data/dataInput/inputs");
const { default: Search } = require("../Search");

class Accommodation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accommodation: [],
      form: {},
      fields: [],
      filteredaccommodation: [],
      searchedValue: null,
      redirect: false,
      redirectTo: "",
      modal: {
        open: false,
      },
      actions: [],
    };
  }

  componentDidMount() {
    reqGetData({ target: "accommodation" }, cb => {
      this.setState({ accommodation: cb });
    });

    getFields({ target: "accommodation", tab: "overview" }, cb => {
      let tempState = { fields: cb.fields, allowDelete: cb.allowDelete, allowAdd: cb.allowAdd };

      if (cb.allowAdd) {
        // add add button
        tempState.actions = [
          {
            value: "add",
            alias: "Add Accommodation",
            action: this.addAccommodation.bind(this),
            startIcon: <PersonAddIcon />,
          },
        ];
      }

      this.setState(tempState);
    });

    this.unsubscribe = store.subscribe(() => {
      let state = store.getState();
      let searchedValue = state.menu.filter ? state.menu.filter.searchedValue : null;
      if (searchedValue && searchedValue !== this.state.searchedValue) {
        dataFilter({ data: this.state.accommodation, filter: searchedValue }, cb => {
          this.setState({ searchedValue, filteredaccommodation: cb });
        });
      }

      if (!searchedValue) {
        this.setState({ searchedValue });
      }
    });
  }

  handleAction(opt) {
    let type = opt.type;
    switch (type) {
      case "view":
        store.dispatch(
          setItem({
            tab: 4,
            type: "view",
            titleFields: ["accommodationName"],
            data: getItem({ data: this.state.accommodation, id: opt.id }),
            origin: "accommodation",
            tabs: getTabs({ target: "accommodation" }),
          })
        );
        this.setState({
          redirect: true,
          redirectTo: "/item",
        });
        break;
      case "delete":
        this.setState({
          modal: {
            open: true,
            text: "Are you sure?",
            title: "Delete farm",
            actions: [
              {
                value: "close",
                alias: "Close",
                action: () => this.setState({ modal: { open: false } }),
                color: "secondary",
                startIcon: <CloseIcon />,
              },
              {
                value: "delete",
                alias: "YES",
                action: () =>
                  this.deleteItem({
                    id: opt.id,
                    target: "accommodation",
                  }),
                color: "secondary",
                startIcon: <DeleteIcon />,
              },
            ],
          },
        });
        break;

      default:
        break;
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  deleteItem = opt => {
    updateData(
      {
        target: opt.target,
        id: opt.id,
        data: { itemState: false },
      },
      cb => {
        if (cb) {
          this.setState({ accommodation: cb, modal: { open: false } });
          store.dispatch(
            setAlert({
              text: "Item deleted",
              open: true,
              timeout: 2000,
              severity: "success",
            })
          );
        } else {
          // not able to delete
          this.setState({ modal: { open: false } });
          store.dispatch(
            setAlert({
              text: "Not able to delete item",
              open: true,
              timeout: 2000,
              severity: "error",
            })
          );
        }
      }
    );
  };

  saveData(opt) {
    reqCreateData({ target: "accommodation", data: this.state.form }, cb => {
      if (cb) {
        // update accommodation
        reqGetData({ target: "accommodation" }, cb => {
          this.setState({
            accommodation: cb,
            modal: { open: false },
            form: {},
          });
        });
      } else {
        // not update // alert user
        store.dispatch(
          setAlert({
            text: "Not able to add accommodation",
            open: true,
            timeout: 2000,
            severity: "error",
          })
        );
      }
    });
  }

  addAccommodation(opt) {
    // force enable inputs if add is hited
    let inputs = [...this.state.fields].map(f => {
      let newF = { ...f };
      newF.disabled = false;
      return newF;
    });

    this.setState({
      modal: {
        open: true,
        title: "Create accommodation",
        inputs,
        actions: [
          {
            value: "close",
            alias: "Close",
            action: () => this.setState({ modal: { open: false } }),
            color: "secondary",
            startIcon: <CloseIcon />,
          },
          {
            value: "save",
            alias: "Save",
            color: "primary",
            action: this.saveData.bind(this),
            startIcon: <SaveIcon />,
          },
        ],
      },
    });
  }

  handleChange(opt) {
    let form = { ...this.state.form };
    form[opt.target] = opt.value;
    this.setState({ form });
  }

  mainComp = opt => {
    let modal = opt.modal;
    let data = opt.data;

    return (
      <Grid container>
        <Header tab={1} />
        <Grid container item>
          <Grid item xs={12} md={6} lg={6} className='my-3'>
            <Typography align='center' variant='h4' className='text-Pmain'>
              {"Accommodations"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={3} className='my-3'>
            <Search />
          </Grid>
          <Grid item xs={12} md={6} lg={3} className='my-3'>
            <Actions actions={this.state.actions} />
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <TableShow
            tableProps={{ size: "small" }}
            allowDelete={this.state.allowDelete}
            heads={this.state.tableHeads}
            headFilters
            lines={data ? data : []}
          />
        </Grid>
        <Dialog open={modal.open} onClose={() => this.setState({ modal: { open: false } })}>
          <DialogTitle>{modal.title}</DialogTitle>
          <DialogContent>
            <Inputs
              inputs={modal.inputs}
              values={this.state.form}
              handleChange={this.handleChange.bind(this)}
            />
          </DialogContent>
          <Grid className='m-2'>
            <Actions actions={modal.actions} />
          </Grid>
        </Dialog>
      </Grid>
    );
  };

  render() {
    let modal = this.state.modal;
    let filter = this.state.searchedValue;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirectTo} />;
    } else {
      var fields = [...this.state.fields];
      fields.push({
        value: "actions",
        alias: "Actions",
        type: "actions",
        variants: [
          {
            value: "view",
            alias: "View",
            icon: "visibility",
            action: opt => this.handleAction({ id: opt, type: "view" }),
          },
          {
            value: "delete",
            alias: "delete",
            icon: "delete",
            action: opt => this.handleAction({ id: opt, type: "delete" }),
          },
        ],
      });

      return (
        <Grid container>
          <Header tab={4} />
          <Grid container item>
            <Grid item xs={12} md={6} lg={6} className='my-3'>
              <Typography align='center' variant='h4' className='text-Pmain'>
                {"Accommodations"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3} className='my-3'>
              <Search />
            </Grid>
            <Grid item xs={12} md={6} lg={3} className='my-3'>
              <Actions actions={this.state.actions} />
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <TableShow
              tableProps={{ size: "small" }}
              heads={fields}
              headFilters
              lines={filter ? this.state.filteredaccommodation : this.state.accommodation}
            />
          </Grid>
          <Dialog
            open={modal.open}
            onClose={() => this.setState({ modal: { open: false } })}
            fullWidth={true}
            maxWidth={"sm"}
          >
            <DialogTitle>{modal.title}</DialogTitle>
            {modal.text ? (
              <Typography variant='h4' align='center'>
                {modal.text}
              </Typography>
            ) : null}
            <DialogContent>
              <Inputs
                inputs={modal.inputs}
                values={this.state.form}
                handleChange={this.handleChange.bind(this)}
              />
            </DialogContent>
            <Grid className='m-2'>
              <Actions actions={modal.actions} />
            </Grid>
          </Dialog>
        </Grid>
      );
    }
  }
}

export default Accommodation;
