import React, { Component } from "react";
import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Grid,
  Typography,
  Hidden,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuIcon from "@material-ui/icons/Menu";
import { Redirect, Link } from "react-router-dom";
import { getTabMenu } from "./../../fn/data";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      redirect: false,
      destination: "",
    };

    this.menuRef = React.createRef();
  }

  handleChange = (e, v) => {
    e.preventDefault();
    let tabs = getTabMenu();
    this.setState(
      {
        redirect: true,
        destination: tabs[v].target,
      },
      () => {
        this.setState({
          redirect: false,
        });
      }
    );
  };

  render() {
    if (this.state.redirect) {
      // we need to redirect
      return <Redirect to={this.state.destination} />;
    } else {
      let tabs = getTabMenu();

      return (
        <AppBar position="static">
          <Toolbar>
            <Grid
              container
              justifyContent="space-between"
              direction="row"
              alignItems="center"
            >
              <Grid container item xs={11} sm={5} lg={3} className="">
                <Grid item>
                  <Link to="/">
                    <img
                      src={"../../../logo-1.png"}
                      alt="Ferme Olanda"
                      height="48px"
                      className="d-block mx-auto"
                    />
                  </Link>
                </Grid>
                <Grid item className="d-flex">
                  <Typography className="my-auto" variant="h5" align="left">
                    Ferme Olanda
                  </Typography>
                </Grid>
              </Grid>
              <Hidden smDown>
                <Grid
                  container
                  item
                  xs={12}
                  sm={7}
                  lg={9}
                  justifyContent="flex-end"
                >
                  <Tabs
                    value={this.props.tab}
                    onChange={this.handleChange.bind(this)}
                    aria-label="icon label tabs"
                  >
                    {tabs.map(t => {
                      return (
                        <Tab
                          label={t.value}
                          id={t.target}
                          key={t.value}
                          icon={t.icon}
                        ></Tab>
                      );
                    })}
                  </Tabs>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item xs={1}>
                  <MenuIcon
                    ref={this.menuRef}
                    fontSize="large"
                    onClick={() => this.setState({ menu: true })}
                  />
                </Grid>
                <Menu
                  id="menuId"
                  style={{ marginTop: "30px" }}
                  anchorEl={this.menuRef.current}
                  keepMounted
                  open={this.state.menu}
                  onClose={() => this.setState({ menu: false })}
                >
                  {tabs.map((t, i) => {
                    return (
                      <MenuItem
                        key={t.value}
                        onClick={e => this.handleChange(e, i)}
                      >
                        <ListItemIcon fontSize="small">{t.icon}</ListItemIcon>
                        {t.value}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Hidden>
            </Grid>
          </Toolbar>
        </AppBar>
      );
    }
  }
}

export default Header;
